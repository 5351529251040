import { AnyAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createReducer, localEnhancer } from 'lib/centralStore';

import { apiMasterTokenHappybet } from 'sites/happybet/features/api/apiMasterTokenHappybet';
import { apiSlice } from 'features/api/apiSlice';
import dialogDeReducer from 'sites/happybet/features/dialogDe/dialogDeSlice';
import { externalApi } from 'features/api/externalApiSlice';
import happybetReducer from 'sites/happybet/features/slices/happybetSlice';
import { liveNavMiddleware } from 'features/live/liveNav/liveNavMiddleware';
import { locationMiddleware } from 'features/location/locationMiddleware';
import { promoEngineApiSlice } from 'features/api/promoEngineApiSlice';
import { rememberReducer } from 'redux-remember';
import { serverSideApi } from 'features/api/serverSideApiSlice';
import signupReducer from '../features/signup/signup';
import { sportTicketMiddleware } from 'features/sport/components/sportTicket/sportTicketMiddleware';

export const rootReducer = createReducer({
  signup: signupReducer,
  dialogDe: dialogDeReducer,
  happybet: happybetReducer,
  [promoEngineApiSlice.reducerPath]: promoEngineApiSlice.reducer,
  [apiMasterTokenHappybet.reducerPath]: apiMasterTokenHappybet.reducer,
});

const createStore = () =>
  configureStore({
    reducer: rememberReducer(rootReducer), //as typeof rootReducer,
    // reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [localEnhancer],
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        // { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
      })
        .concat(apiSlice.middleware)
        .concat(externalApi.middleware)
        .concat(serverSideApi.middleware)
        .concat(promoEngineApiSlice.middleware)
        .concat(apiMasterTokenHappybet.middleware)
        .concat(sportTicketMiddleware)
        .concat(locationMiddleware)
        .concat(liveNavMiddleware),
  });
export type HpybetReducerType = typeof rootReducer;

export const store = createStore();
export type RootState = ReturnType<HpybetReducerType>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
