import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { signOut } from 'features/auth/authActions';
import { PostLoginModalType } from 'sites/happybet/components/modalsWelcome/ModalsWelcome';

// Define a type for the slice state

export interface DialogDeState {
  modalDepositsLast30Days?: boolean;
  dialogOnlyDeInfo: DialogInfo;
  // logoutAfter60MinutesTimerActive: boolean;
  modalsToOpen: PostLoginModalType[];
}

export type DialogInfo = {
  isOpen: boolean;
  title?: string | null;
  subtitle?: string | null;
  info?: string | null;
  icon?: 'info' | 'question' | 'autoesclusion-24h' | 'exclamation_outline' | 'pig' | 'filter-vertical';
  buttons?: React.ReactNode[] | null;
  closeAction?: () => void;
  type?: string;
};

// Define the initial state using that type
const initialState: DialogDeState = {
  // logoutAfter60MinutesTimerActive: false,
  dialogOnlyDeInfo: {
    isOpen: false,
    title: null,
    icon: 'info',
    subtitle: null,
    info: null,
    buttons: null,
  },
  modalsToOpen: [],
};

const dialogDeSlice = createSlice({
  name: 'dialogDe',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    modalDepositsLast30DaysChange: (state, action: PayloadAction<boolean>) => {
      state.modalDepositsLast30Days = action.payload;
    },
    dialogOnlyDeInfoChange: (state, action: PayloadAction<DialogInfo>) => {
      state.dialogOnlyDeInfo = action.payload;
    },
    setModalsToOpen: (state, action: PayloadAction<PostLoginModalType[]>) => {
      state.modalsToOpen = action.payload;
    },
    resetStateDialogDe: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.pending.type, () => {
      return initialState;
    });
  },
});

export const { modalDepositsLast30DaysChange, dialogOnlyDeInfoChange, resetStateDialogDe, setModalsToOpen } =
  dialogDeSlice.actions;

export default dialogDeSlice.reducer;
