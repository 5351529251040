import { appInsight, appInsightScommessaReady } from 'components/appInsight';
import { isLoadingVenditaChanged, venditaAlertFailedOpenChange } from 'features/carrello/carrelloSlice';
import {
  initScommessaResponse,
  initTemplateAvvenimento,
  initTemplateAvvenimentoMarcatore,
  initTemplateDirettissime,
  initTemplateDisciplina,
  initTemplateLive,
  initTemplateManifestazione,
  initTemplateSnaiRunner,
  setSlug,
} from 'features/sport/sportSlice';
import {
  CartCancellaPrenotazioneRequestDto,
  SportsAvvenimentoEsposto,
  SportsCacheMenuManifestazioniSportWithTranslationsDto,
  SportsCacheScommesseCoreWithTranslationsDto,
  SportsCacheScommesseMarcatoriDto,
  SportsCacheTemplateAvvenimentoDto,
  SportsCacheTemplateAvvenimentoMarcatoreWithTranslationsDto,
  SportsCacheTemplateAvvenimentoWithTranslationsDto,
  SportsCacheTemplateDisciplinaDto,
  SportsCacheTemplateDisciplinaLiveDto,
  SportsCacheTemplateDisciplinaLiveWithTranslationsDto,
  SportsCacheTemplateDisciplinaWithTranslationsDto,
  SportsCacheTemplateManifestazioneCustomSectionDto,
  SportsCacheTemplateManifestazioneCustomSectionWithTranslationsDto,
  SportsCacheTemplateManifestazioneDto,
  SportsCacheTemplateManifestazioneMarcatoriDto,
  SportsCacheTemplateManifestazioneWithTranslationsDto,
  SportsStreamingProviderEnum,
  SportsStreamingResponseDto,
  SportsTipoScommessa,
  SportsTopVinciteDto,
  UsersUserDataSectionEnum,
} from 'types/swagger';
import { EsitoMap, ScommessaResponse } from './../../lib/api/sport/sportScommesseBySlugResponse';
import { ApiPayload, ApiStatus } from './thunkUtils';

import { AddManifestazioneToPreferitiPayload } from 'features/sport/components/sportNav/sportNavAccordion/components/sportNavAccordionContent/SportNavAccordionContent';
import { venditaSport } from 'features/sport/components/sportTicket/sportTicketActions';
import { bonusConfigurationAdded } from 'features/sport/components/sportTicket/sportTicketSlice';
import { SportTicket } from 'features/sport/components/sportTicket/utils/calcoloCarrello/types';
import { setAvvenimentoSelected } from 'features/sport/marcatoriSlice';
import { VirtualTicket } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/types';
import { feedLingUI } from 'hooks/useLingUI';
import { CartBonusResponse } from 'lib/api/sport/cartBonusResponseApi';
import { DirettissimeResponseApi } from 'lib/api/sport/direttissimeResponseApi';
import { SnaiRunnerResponseApi } from 'lib/api/sport/snaiRunnerResponseApi';
import { SportLiveStreamingRequestApi } from 'lib/api/sport/sportLiveStreamingRequestApi';
import { SportNewsResponseApi } from 'lib/api/sport/sportNewsResponseApi';
import { SportPrenotaScommessaResponse } from 'lib/api/sport/sportPrenotaScommessaApi';
import { SportPrenotaScommessaInitSessionResponse } from 'lib/api/sport/sportPrenotaScommessaInitSessionApi';
import { SportScommesseImpostazioni } from 'lib/api/sport/sportScommesseImpostazioniApi';
import { ScommettiResponse } from 'lib/api/sport/sportScommettiResponseApi';
import { SviluppoSistemiResponse } from 'lib/api/sport/sportSviluppoSistemiResponseApi';
import { SportsCacheTemplateAvvenimentoMarcatoreDto, SportsSquadraItem } from 'types/swagger/sports';
import { isSnaiSite } from 'utility/constant';
import { toQueryString } from 'utility/functions';
import { apiSlice } from './apiSlice';

const templateManifestazioneEndPoint = 'sports/sports/template-manifestazione';
const templateManifestazioneCustomEndPoint = 'sports/sports/template-custom-section';
const templateDisciplinaEndPoint = 'sports/sports/template-disciplina';
const templateAvvenimentoEndPoint = 'sports/sports/template-avvenimento';
const templateTopEndPoint = '/sports/live/template-top';
const scommesseEndPoint = '/sports/sports/scommesse';
const venditaEndPoint = `/cart/vendita`;
const venditaEndPointHappybet = `/cart/hbet/vendita`;

export interface TemplateLiveParams {
  slug: string;
  filters?: Record<string, Array<string>>;
}

export const extendedApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMenuManifestazioniBySlug: build.query<SportsCacheMenuManifestazioniSportWithTranslationsDto, string>({
      query: (slug) => `sports/sports/menu-manifestazioni?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { traduzioneMap } = data;
          feedLingUI(traduzioneMap);
        } catch (exception) {
          appInsight.trackException({ exception, id: `sports/sports/menu-manifestazioni?slug=${arg}` });
        }
      },
      providesTags: ['Manifestazioni'],
    }),
    getTemplateManifestazioneBySlug: build.query<SportsCacheTemplateManifestazioneWithTranslationsDto, string>({
      query: (slug) => `${templateManifestazioneEndPoint}?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(
            initTemplateManifestazione({
              status: ApiStatus.loading,
            } as ApiPayload<SportsCacheTemplateManifestazioneDto>)
          );
          const { data } = await queryFulfilled;

          const { traduzioneMap, ...result } = data;
          feedLingUI(traduzioneMap);

          dispatch(initTemplateManifestazione(result));
        } catch (exception) {
          dispatch(
            initTemplateManifestazione({ status: ApiStatus.failed } as ApiPayload<SportsCacheTemplateManifestazioneDto>)
          );
          appInsight.trackException({ exception, id: `${templateManifestazioneEndPoint}?slug=${decodeURI(arg)}` });
        }
      },
    }),
    getTemplateManifestazioneCustomBySlug: build.query<
      SportsCacheTemplateManifestazioneCustomSectionWithTranslationsDto,
      string
    >({
      query: (slug) => `${templateManifestazioneCustomEndPoint}?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(
            initTemplateManifestazione({
              status: ApiStatus.loading,
            } as ApiPayload<SportsCacheTemplateManifestazioneCustomSectionDto>)
          );
          const { data } = await queryFulfilled;

          const { traduzioneMap, ...result } = data;
          feedLingUI(traduzioneMap);

          dispatch(initTemplateManifestazione(result));
        } catch (exception) {
          dispatch(
            initTemplateManifestazione({
              status: ApiStatus.failed,
            } as ApiPayload<SportsCacheTemplateManifestazioneCustomSectionDto>)
          );
          appInsight.trackException({
            exception,
            id: `${templateManifestazioneCustomEndPoint}?slug=${decodeURI(arg)}`,
          });
        }
      },
    }),
    getTemplateMarcatoriBySlug: build.query<SportsCacheTemplateManifestazioneMarcatoriDto, string>({
      query: (slug) => `/sports/sports/template-manifestazione-marcatori?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(
            initTemplateManifestazione({
              status: ApiStatus.loading,
            } as ApiPayload<SportsCacheTemplateManifestazioneDto>)
          );
          const { data } = await queryFulfilled;

          dispatch(initTemplateManifestazione(data));
        } catch (exception) {
          dispatch(
            initTemplateManifestazione({ status: ApiStatus.failed } as ApiPayload<SportsCacheTemplateManifestazioneDto>)
          );
          appInsight.trackException({ exception, id: `${templateManifestazioneEndPoint}?slug=${decodeURI(arg)}` });
        }
      },
    }),
    getScommesseMarcatoryBySlug: build.query<SportsCacheScommesseMarcatoriDto, string>({
      query: (slug) => `/sports/sports/scommesse-marcatori?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(initScommessaResponse({ status: ApiStatus.loading }));
          const { data } = await queryFulfilled;

          dispatch(initScommessaResponse({ data: data as unknown as ScommessaResponse, status: ApiStatus.idle }));
          dispatch(setSlug(arg));
          setTimeout(() => {
            appInsightScommessaReady();
          }, 0);
        } catch (exception) {
          dispatch(initScommessaResponse({ status: ApiStatus.failed }));
          setTimeout(() => {
            appInsightScommessaReady(true);
          }, 0);
          // console.error(`${scommesseEndPoint}?slug=${arg}`, exception);
          appInsight.trackException({ exception, id: `${scommesseEndPoint}?slug=${decodeURI(arg)}` });
        }
      },
    }),
    getTemplateAvvenimentoMarcatoriBySlug: build.query<
      SportsCacheTemplateAvvenimentoMarcatoreWithTranslationsDto,
      string
    >({
      query: (slug) => `/sports/sports/template-avvenimento-marcatori?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(
            initTemplateAvvenimentoMarcatore({
              status: ApiStatus.loading,
            } as ApiPayload<SportsCacheTemplateAvvenimentoMarcatoreDto>)
          );
          const { data } = await queryFulfilled;

          const { traduzioneMap, ...result } = data;
          feedLingUI(traduzioneMap);

          dispatch(initTemplateAvvenimentoMarcatore(result));
        } catch (exception) {
          dispatch(
            initTemplateAvvenimentoMarcatore({
              status: ApiStatus.failed,
            } as ApiPayload<SportsCacheTemplateAvvenimentoMarcatoreDto>)
          );
          appInsight.trackException({ exception, id: `${templateAvvenimentoEndPoint}?slug=${decodeURI(arg)}` });
        }
      },
    }),
    getTemplateDisciplinaBySlug: build.query<SportsCacheTemplateDisciplinaWithTranslationsDto, string>({
      query: (slug) => `${templateDisciplinaEndPoint}?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(
            initTemplateDisciplina({ status: ApiStatus.loading } as ApiPayload<SportsCacheTemplateDisciplinaDto>)
          );
          const { data } = await queryFulfilled;

          const { traduzioneMap, ...result } = data;
          feedLingUI(traduzioneMap);

          dispatch(initTemplateDisciplina(result));
        } catch (exception) {
          dispatch(
            initTemplateDisciplina({ status: ApiStatus.failed } as ApiPayload<SportsCacheTemplateDisciplinaDto>)
          );
          appInsight.trackException({ exception, id: `${templateDisciplinaEndPoint}?slug=${decodeURI(arg)}` });
        }
      },
    }),
    getTemplateAvvenimentoBySlug: build.query<SportsCacheTemplateAvvenimentoWithTranslationsDto, string>({
      query: (slug) => `${templateAvvenimentoEndPoint}?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(
            initTemplateAvvenimento({ status: ApiStatus.loading } as ApiPayload<SportsCacheTemplateAvvenimentoDto>)
          );
          const { data } = await queryFulfilled;

          const { traduzioneMap, ...result } = data;
          feedLingUI(traduzioneMap);

          dispatch(initTemplateAvvenimento(result));
        } catch (exception) {
          dispatch(
            initTemplateAvvenimento({ status: ApiStatus.failed } as ApiPayload<SportsCacheTemplateAvvenimentoDto>)
          );
          appInsight.trackException({ exception, id: `${templateAvvenimentoEndPoint}?slug=${decodeURI(arg)}` });
        }
      },
    }),
    // getTopManifestazioni: build.query<SportTopManifestazioniResponseApi, string>({
    //   query: (slug) => `/sports/sports/top-manifestazioni?slug=${slug}`,
    // }),
    getScommessePreMatch: build.query<SportsCacheScommesseCoreWithTranslationsDto, string>({
      query: (slug) => `${scommesseEndPoint}?slug=${slug?.toLowerCase()}`,
      keepUnusedDataFor: 0,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(initScommessaResponse({ status: ApiStatus.loading }));
          const { data } = await queryFulfilled;

          const { traduzioneMap, ...result } = data;
          feedLingUI(traduzioneMap);

          dispatch(initScommessaResponse({ data: result as unknown as ScommessaResponse, status: ApiStatus.idle }));
          dispatch(setSlug(arg));
          setTimeout(() => {
            appInsightScommessaReady();
          }, 0);
        } catch (exception) {
          dispatch(initScommessaResponse({ status: ApiStatus.failed }));
          setTimeout(() => {
            appInsightScommessaReady(true);
          }, 0);
          // console.error(`${scommesseEndPoint}?slug=${arg}`, exception);
          appInsight.trackException({ exception, id: `${scommesseEndPoint}?slug=${decodeURI(arg)}` });
        }
      },
    }),
    getAvvenimento: build.query<SportsCacheScommesseCoreWithTranslationsDto, string>({
      query: (slug) => `${scommesseEndPoint}?slug=${slug}`,
      keepUnusedDataFor: 0,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const { traduzioneMap } = data;
          feedLingUI(traduzioneMap);
        } catch (exception) {
          appInsight.trackException({ exception, id: `${scommesseEndPoint}?slug=${decodeURI(arg)}` });
        }
      },
    }),
    addManifestazioneToPreferiti: build.mutation<any, AddManifestazioneToPreferitiPayload>({
      query: ({ id, slug, descrizione }) => ({
        url: `users/user/favorites/sports/manifestazione`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          rule: {
            id,
            slug,
            descrizione,
          },
          verticale: UsersUserDataSectionEnum.FAVORITE_SPORT_MANIFESTAZIONE,
        },
      }),
      invalidatesTags: ['Manifestazioni'],
    }),
    // getMenuAvvenimentiLive: build.query<any, void>({
    //   query: () => `sports/live/menu-avvenimento`,
    // }),
    getSearchTipiScommessa: build.query<Array<SportsTipoScommessa>, { slug: string; searchValue: string }>({
      query: ({ slug, searchValue }) => `/sports/sports/search-tipi-scommessa?slug=${slug}&word=${searchValue}`,
      keepUnusedDataFor: 0.01,
    }),
    scommettiSingolaMultiplaSport: build.mutation<ScommettiResponse, SportTicket>({
      query: (sportTicket) => ({
        url: isSnaiSite
          ? `${venditaEndPoint}/sport-singola-multipla`
          : `${venditaEndPointHappybet}/sport-singola-multipla`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: sportTicket,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          await dispatch(venditaSport(data));
          dispatch(isLoadingVenditaChanged(false));
        } catch (err) {
          // `onError` side-effect
          // console.error('Failed vendita: ', err);
          dispatch(isLoadingVenditaChanged(false));
          dispatch(venditaAlertFailedOpenChange(true));
        }
      },
      invalidatesTags: ['Wallet'],
    }),
    scommettiSistemaSport: build.mutation<ScommettiResponse, SportTicket>({
      query: (sportTicket) => ({
        url: isSnaiSite ? `${venditaEndPoint}/sport-sistema` : `${venditaEndPointHappybet}/sport-sistema`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: sportTicket,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          await dispatch(venditaSport(data));
          dispatch(isLoadingVenditaChanged(false));
        } catch (err) {
          // `onError` side-effect
          // console.error('Failed vendita: ', err);
          dispatch(isLoadingVenditaChanged(false));
          dispatch(venditaAlertFailedOpenChange(true));
        }
      },
      invalidatesTags: ['Wallet'],
    }),
    prenotaScommessaInitSession: build.mutation<SportPrenotaScommessaInitSessionResponse, void>({
      query: () => ({
        url: `/cart/prenotazione/init-session`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: {},
      }),
    }),
    prenotaScommessa: build.mutation<
      SportPrenotaScommessaResponse,
      { codiceSessione: string } & (SportTicket | VirtualTicket)
    >({
      query: (ticketPrenotato) => ({
        url: `/cart/prenotazione/prenota-scommessa`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: ticketPrenotato,
      }),
    }),
    cancellaPrenotazione: build.mutation<
      {
        statusCodeResponse: number;
        errore: string;
      },
      CartCancellaPrenotazioneRequestDto
    >({
      query: (ticketPrenotato) => ({
        url: `/cart/prenotazione/cancella-prenotazione`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: ticketPrenotato,
      }),
    }),
    getImpostazioniScommessa: build.query<SportScommesseImpostazioni, void>({
      query: () => '/cart/vendita/impostazioni-scommesse',
      providesTags: ['ImpostazioniScommessa'],
    }),
    updateImpostazioniScommessa: build.mutation<
      {
        statusCodeResponse: number;
        errore: string;
      },
      SportScommesseImpostazioni
    >({
      query: (body) => ({
        url: '/cart/vendita/impostazioni-scommesse',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['ImpostazioniScommessa'],
    }),
    runSistemiSimulatore: build.mutation({
      query: (initialPost) => ({
        url: `${process.env.NEXT_PUBLIC_SPORT_SIMULATORE_BASE_URL}`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: initialPost,
      }),
    }),
    getTemplateLive: build.query<SportsCacheTemplateDisciplinaLiveWithTranslationsDto, TemplateLiveParams>({
      query: (params) => ({
        url: `${templateTopEndPoint}?${toQueryString(params)}`,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(initTemplateLive({ status: ApiStatus.loading } as ApiPayload<SportsCacheTemplateDisciplinaLiveDto>));
          const { data } = await queryFulfilled;

          const { traduzioneMap, ...result } = data;
          feedLingUI(traduzioneMap);

          dispatch(initTemplateLive(result));
        } catch (exception) {
          dispatch(initTemplateLive({ status: ApiStatus.failed } as ApiPayload<SportsCacheTemplateDisciplinaLiveDto>));
          // console.error(`${templateTopEndPoint}?slug=${arg}`, exception);
          appInsight.trackException({ exception, id: `${templateTopEndPoint}?slug=${JSON.stringify(arg)}` });
        }
      },
    }),
    getLiveStreaming: build.query<SportsStreamingResponseDto, SportLiveStreamingRequestApi>({
      query: (body) => ({
        method: 'POST',
        url: `/sports/live/streaming`,
        body,
      }),
      transformResponse: async (response: SportsStreamingResponseDto) => {
        if (response.type === SportsStreamingProviderEnum.Img) {
          // console.log(response);
          if (!response.url) {
            appInsight.trackException({
              exception: new Error('IMG PROVIDER WITHOUT ANY URL'),
              id: 'img-provider-without-any-url',
              properties: {
                response,
              },
            });
            throw new Error('IMG PROVIDER WITHOUT ANY URL');
          }
          const imgResponse: { hlsUrl: string; statusCode: number; eventId: number } = await (
            await fetch(response.url)
          ).json();

          if (imgResponse.statusCode !== 200) {
            throw imgResponse;
          }

          const formattedResponse: SportsStreamingResponseDto = {
            dataorarisposta: Date.now().toString(),
            descrizione: '',
            esito: 1024,
            type: SportsStreamingProviderEnum.Img,
            url: imgResponse.hlsUrl,
          };
          return formattedResponse;
        }
        return response;
      },
    }),
    getSharedTicket: build.query<SportTicket, { idTicket: string }>({
      query: ({ idTicket }) => ({
        url: `/cart/prenotazione/ticket-condiviso`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: {
          idTicket,
          fisico: 0,
        },
      }),
    }),
    sviluppoSistemiVincite: build.mutation<SviluppoSistemiResponse, SportTicket>({
      query: (body) => ({
        url: '/cart/vendita/sviluppo-sistemi',
        method: 'POST',
        body: body,
      }),
    }),
    updateQuoteBySportTicketAvvenimentoList: build.mutation<
      {
        esitoMap: EsitoMap;
        avvenimentiSospesi: {
          [key: string]: boolean;
        };
        infoAggiuntiveSospese: {
          [key: string]: boolean;
        };
      },
      {
        [key: string]: boolean;
      }
    >({
      query: (body) => ({
        url: '/sports/sports/cart-update',
        method: 'POST',
        body: body,
      }),
    }),
    getCartBonus: build.query<CartBonusResponse, void>({
      query: () => `/users/user/bonus-carrello-sport-offline`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(bonusConfigurationAdded(data));
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getAvvenimentoDetail: build.query<SportsAvvenimentoEsposto, { slug: string }>({
      query: ({ slug }) => `/sports/sports/get-avvenimento?slug=${slug}`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(initScommessaResponse({ status: ApiStatus.loading }));

          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(
            initScommessaResponse({
              data: {
                avvenimentoList: [data],
                esitoMap: {},
                infoAggiuntivaMap: {},
                infoTipoScommessaGroupMap: {},
                infoTipoScommessaMap: {},
                scommessaMap: {},
              },
            })
          );
        } catch (err) {
          // `onError` side-effect
          console.log(err);
          dispatch(initScommessaResponse({ status: ApiStatus.failed }));
        }
      },
    }),
    getGuardaSuSnai: build.query<SportsAvvenimentoEsposto[], void>({
      query: () => `/sports/sports/guarda-su-snai`,
    }),
    getDirettissime: build.query<DirettissimeResponseApi, string>({
      query: (slug) => `/sports/sports/direttissime?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          if (data) {
            dispatch(
              initTemplateDirettissime({
                data: {
                  direttissime: data.direttissime,
                  ...data.scommesse,
                },
              })
            );
          }
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getSportNews: build.query<SportNewsResponseApi, undefined | string>({
      query: (slug) => (slug === undefined ? `/sports/sports/news` : `/sports/sports/news?slug=${slug}`),
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
    }),
    getTopVincite: build.query<SportsTopVinciteDto, void>({
      query: () => `/sports/sports/top-vincite`,
    }),
    getSnaiRunner: build.query<SnaiRunnerResponseApi, void>({
      query: () => `/sports/sports/snai-runner`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          if (data) {
            dispatch(
              initTemplateSnaiRunner({
                data: {
                  tabList: data.tabList,
                  ...(data.scommesse as unknown as ScommessaResponse),
                },
              })
            );
          }
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getTeams: build.query<Array<SportsSquadraItem>, string>({
      query: (slug) => (!slug ? `/sports/sports/squadre` : `/sports/sports/squadre?slug=${slug.toLowerCase()}`),
    }),
  }),
  overrideExisting: false,
});

export const {
  usePrefetch,
  useGetTeamsQuery,
  useGetSportNewsQuery,
  useLazyGetTeamsQuery,
  useGetSnaiRunnerQuery,
  useGetTopVinciteQuery,
  useGetGuardaSuSnaiQuery,
  useGetDirettissimeQuery,
  useGetTemplateLiveQuery,
  useGetSharedTicketQuery,
  useGetLiveStreamingQuery,
  useLazyGetCartBonusQuery,
  useLazyGetSportNewsQuery,
  usePrenotaScommessaMutation,
  useLazyGetTemplateLiveQuery,
  useGetScommessePreMatchQuery,
  useGetSearchTipiScommessaQuery,
  useCancellaPrenotazioneMutation,
  useLazyGetAvvenimentoDetailQuery,
  useLazyGetScommessePreMatchQuery,
  useScommettiSistemaSportMutation,
  useGetImpostazioniScommessaQuery,
  useSviluppoSistemiVinciteMutation,
  useGetTemplateMarcatoriBySlugQuery,
  useLazyGetSearchTipiScommessaQuery,
  useGetTemplateDisciplinaBySlugQuery,
  useGetMenuManifestazioniBySlugQuery,
  useGetScommesseMarcatoryBySlugQuery,
  useGetTemplateAvvenimentoBySlugQuery,
  usePrenotaScommessaInitSessionMutation,
  useUpdateImpostazioniScommessaMutation,
  useLazyGetMenuManifestazioniBySlugQuery,
  useGetTemplateManifestazioneBySlugQuery,
  useLazyGetTemplateMarcatoriBySlugQuery,
  useLazyGetTemplateDisciplinaBySlugQuery,
  useLazyGetScommesseMarcatoryBySlugQuery,
  useAddManifestazioneToPreferitiMutation,
  useLazyGetTemplateAvvenimentoBySlugQuery,
  useScommettiSingolaMultiplaSportMutation,
  useLazyGetTemplateManifestazioneBySlugQuery,
  useGetTemplateManifestazioneCustomBySlugQuery,
  useGetTemplateAvvenimentoMarcatoriBySlugQuery,
  useLazyGetTemplateManifestazioneCustomBySlugQuery,
  useUpdateQuoteBySportTicketAvvenimentoListMutation,
} = extendedApi;

export const {
  getScommessePreMatch,
  getTemplateDisciplinaBySlug,
  getMenuManifestazioniBySlug,
  getTemplateAvvenimentoBySlug,
  getTemplateManifestazioneBySlug,
} = extendedApi.endpoints;
