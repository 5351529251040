import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface HappybetSlice {
  userActivity?: UserActivity | null;
  jumioModalAfterRegistrationOpen: boolean;
  isCustomerCard?: boolean;
}

export interface UserActivity {
  stakes?: string;
  losses?: string;
  winnings_paied?: string;
}

// Define the initial state using that type
const initialState: HappybetSlice = {
  userActivity: null,
  jumioModalAfterRegistrationOpen: false,
  isCustomerCard: false,
};

export const happybetSlice = createSlice({
  name: 'happybet',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserActivity: (state, action: PayloadAction<UserActivity>) => {
      state.userActivity = action.payload;
    },
    setJumioModalAfterRegistrationOpen: (state, action: PayloadAction<boolean>) => {
      state.jumioModalAfterRegistrationOpen = action.payload;
    },
    setIsCustomerCard: (state, action: PayloadAction<boolean | undefined>) => {
      state.isCustomerCard = action.payload;
    },
  },
});

export const { setUserActivity, setJumioModalAfterRegistrationOpen, setIsCustomerCard } = happybetSlice.actions;

export default happybetSlice.reducer;
