import { createContext, useContext } from 'react';
import { DatoCmsModalItem } from '../lib/datoCms/types';

export type ModalsContext = DatoCmsModalItem[];

const ModalsContext = createContext<ModalsContext>([]);

export const useModals = () => {
  const modalsContext = useContext(ModalsContext);

  return modalsContext;
};

export { ModalsContext };
