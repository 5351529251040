export const HPYBET_SRC = 'https://*.hpybet.com';
export const HPYBET_SRC_DE = 'https://*.hpybet.de';

export const JUMIO_SRC = 'https://*.jumio.ai';
export const TYPEKIT_SRC = 'https://*.typekit.net';
export const WOOSMAP_SRC = 'https://*.woosmap.com';
export const COOKIEBOT_SRC = 'https://*.cookiebot.com';
export const DYNATRACE_SRC = 'https://*.dynatrace.com';
export const AKAMAI_PULSE_SRC = 'https://*.go-mpulse.net';
export const CHAT_SERVICE_SRC = 'https://chatservice.snai.it/webplayer/';
export const GOOGLE_ANALYTICS_SRC = 'https://*.google-analytics.com';

export const DATOCMS_URL = 'https://www.datocms-assets.com';
export const SPID_REGISTRY_URL = 'https://registry.spid.gov.it';
export const ACSBAPP_URL = 'https://acsbapp.com/apps/app/dist/js/app.js';
export const CDNFORNT_URL = 'https://fonts.cdnfonts.com';
export const IOVATION_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/api/iovation`;
export const DEP_IOVATION_URL = 'https://mpsnare.iesnare.com';
export const CAPTAINUP_SRC = 'https://captainup.com/assets/sdk.js';
export const LIVE_STREAMING_URL = `https://livestream-snai.akamaized.net`;
export const GOOGLE_TAG_MANAGER_URL = 'https://www.googletagmanager.com/gtm.js';
export const APPLICATION_INSIGHTS_TRACK_URL = 'https://*.applicationinsights.azure.com/v2/track';
export const DIGITAL_ASSISTANT_URL = `${CHAT_SERVICE_SRC}latest/js/digital-assistant.js?cid=205d106b17323bdccede4dca5681a4c994b54562&view.mobileMode=detect`;

const WS = 'ws:';
const WSS = 'wss:';
const BLOB = 'blob:';
const DATA = 'data:';
const SELF = "'self'";
// const HTTPS = 'https:';
const UNSAFE_EVAL = "'unsafe-eval'";
const UNSAFE_INLINE = "'unsafe-inline'";
// const STRICT_DYNAMIC = "'strict-dynamic'";
export const NONCE_PLACEHOLDER = '//-=NoNcE=-//';

export const techs = [
  SELF,
  HPYBET_SRC,
  HPYBET_SRC_DE,
  `${process.env.NEXT_PUBLIC_BASE_URL}`.toLowerCase().indexOf('.hpybet.com') < 0
    ? `${process.env.NEXT_PUBLIC_BASE_URL}`
    : '',
];

export const defaults = [WS, WSS, DATOCMS_URL, GOOGLE_ANALYTICS_SRC, process.env.NEXT_PUBLIC_TOTOCALCIO_URL];
export const scriptElems = [
  ACSBAPP_URL,
  COOKIEBOT_SRC,
  CAPTAINUP_SRC,
  DYNATRACE_SRC,
  AKAMAI_PULSE_SRC,
  DEP_IOVATION_URL,
  GOOGLE_TAG_MANAGER_URL,
  UNSAFE_INLINE,
];
export const connections = [
  WS,
  WSS,
  TYPEKIT_SRC,
  DATOCMS_URL,
  ACSBAPP_URL,
  DYNATRACE_SRC,
  COOKIEBOT_SRC,
  CAPTAINUP_SRC,
  SPID_REGISTRY_URL,
  LIVE_STREAMING_URL,
  GOOGLE_ANALYTICS_SRC,
  GOOGLE_TAG_MANAGER_URL,
  APPLICATION_INSIGHTS_TRACK_URL,
];
export const imgmages = [BLOB, DATA, DATOCMS_URL, GOOGLE_ANALYTICS_SRC];
export const scripts = [
  BLOB,
  WOOSMAP_SRC,
  TYPEKIT_SRC,
  ACSBAPP_URL,
  DYNATRACE_SRC,
  COOKIEBOT_SRC,
  CHAT_SERVICE_SRC,
  DEP_IOVATION_URL,
  GOOGLE_TAG_MANAGER_URL,
  process.env.NEXT_PUBLIC_LAUNCH_GAN,
  process.env.NEXT_PUBLIC_LAUNCH_CAPECOD,
  process.env.NEXT_PUBLIC_LAUNCH_BETPOINT,
  process.env.NEXT_PUBLIC_LAUNCH_GAME_360,
  process.env.NEXT_PUBLIC_LAUNCH_ISOFTBET,
  process.env.NEXT_PUBLIC_LAUNCH_MICROGAME,
  process.env.NEXT_PUBLIC_SCRIPT_URL_POKER,
  process.env.NEXT_PUBLIC_CAPTAIN_UP_SDK_URL,
  process.env.NEXT_PUBLIC_VETRINA_SISAL_SCRIPT,
  process.env.NEXT_PUBLIC_LAUNCH_GOL_SLOT_CASINO,
  process.env.NEXT_PUBLIC_PROTOTYPE_LOTTERY_SCRIPT,
  process.env.NEXT_PUBLIC_LAUNCH_FIM_PLAYTECH_CASINO,
  process.env.NEXT_PUBLIC_VETRINA_GRATTA_E_VINCI_SCRIPT,
  process.env.NEXT_PUBLIC_GRATTA_E_VINCI_SCRIPT_SHOWCASE,
  UNSAFE_EVAL,
  UNSAFE_INLINE,
];
export const styles = [TYPEKIT_SRC, CDNFORNT_URL, UNSAFE_INLINE];
export const frames = [
  JUMIO_SRC,
  COOKIEBOT_SRC,
  process.env.NEXT_PUBLIC_PROMO_URL,
  process.env.NEXT_PUBLIC_SNAI_PLUS_URL,
  process.env.NEXT_PUBLIC_BETGENIUS_URL,
  process.env.NEXT_PUBLIC_SCOREBOARD_URL,
  process.env.NEXT_PUBLIC_LAUNCH_SNAISKILL,
  process.env.NEXT_PUBLIC_LAUNCH_GOL_GIOCHI,
  process.env.NEXT_PUBLIC_SEAMLESS_BASE_URL,
  process.env.NEXT_PUBLIC_LOTTOMATICA_IFRAME,
  process.env.NEXT_PUBLIC_GRATTA_E_VINCI_IFRAME,
  process.env.NEXT_PUBLIC_VIRTUAL_WIDGET_IFRAME,
  process.env.NEXT_PUBLIC_LAUNCH_ULR_PLAYTECH_POKER,
  process.env.NEXT_PUBLIC_VIRTUAL_CLASSIFICA_GOLDEN,
  process.env.NEXT_PUBLIC_LAUNCH_CASINO_LIVE_MOBILE,
  process.env.NEXT_PUBLIC_LAUNCH_CASINO_LIVE_DESKTOP,
  process.env.NEXT_PUBLIC_LAUNCH_ULR_PLAYTECH_CASINO,
  UNSAFE_INLINE,
];
export const media = [BLOB, DATA, DEP_IOVATION_URL];
export const fonts = [DATA, TYPEKIT_SRC, CDNFORNT_URL];
