import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AccessInfo = {
  username: string;
  password: string;
  secretQuestion: string;
  secretAnswer: string;
};

export type PersonalInformation = {
  name: string;
  surname: string;
  geburtsname: string;
  birthDate: string;
  gender?: '1' | '2';
  cityOfBirth: string;
  nationality: string;
  state: string;
  address: string;
  province: string;
  city: string;
  cap: string;
};

type ContactsData = {
  email: string;
  userSessionEmail?: string;
  prefix: string;
  telephone: string;
  userSessionTelephone?: string;
};
type PrivacyConsentsData = {
  thirdPartyInformation: boolean;
  acceptTermAndCondition: boolean;
  privacyInformation: boolean;
  profilingUsage: boolean;
  marketingUsage: boolean;
};

type DocumentData = {
  documentType: string;
  documentNumber: string;
  releasedBy: string;
  releasePlace: string;
  releaseDate: string;
  expirationDate: string;
};
type PromoData = {
  promoCode: string;
};
type MaximalData = {
  weeklyMaximal: string;
};
type ActiveAccount = {
  contractCode: string;
  pinCode: string;
};

type AntiFraudType = {
  antiFraudCode: string;
};

type ExtraInformation = {
  btag?: string;
  provenienceCode?: string;
};

export type SignupState = AccessInfo &
  ContactsData &
  PrivacyConsentsData &
  PersonalInformation &
  DocumentData &
  PromoData &
  MaximalData &
  ActiveAccount &
  AntiFraudType &
  ExtraInformation;
const initialState: SignupState = {
  username: '',
  password: '',
  secretQuestion: '',
  secretAnswer: '',
  email: '',
  prefix: '',
  telephone: '',
  name: '',
  surname: '',
  geburtsname: '',
  birthDate: '',
  cityOfBirth: '',
  nationality: '',
  state: '',
  address: '',
  province: '',
  city: '',
  cap: '',
  documentType: '',
  documentNumber: '',
  releasedBy: '',
  releasePlace: '',
  releaseDate: '',
  expirationDate: '',
  promoCode: '',
  weeklyMaximal: '',
  thirdPartyInformation: false,
  acceptTermAndCondition: false,
  privacyInformation: false,
  profilingUsage: false,
  marketingUsage: false,
  contractCode: '',
  pinCode: '',
  antiFraudCode: '',
};

export type UnregisterModal = {
  feedbackUnregistration: string;
};

const signupSlice = createSlice({
  name: 'Signup',
  initialState,
  reducers: {
    setAccessData: (state, action: PayloadAction<AccessInfo>) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.secretQuestion = action.payload.secretQuestion;
      state.secretAnswer = action.payload.secretAnswer;
    },
    setContacts: (state, action: PayloadAction<ContactsData>) => {
      state.email = action.payload.email;
      state.prefix = action.payload.prefix;
      state.telephone = action.payload.telephone;
    },
    setPersonalData: (state, action: PayloadAction<PersonalInformation>) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.birthDate = action.payload.birthDate;
      state.gender = action.payload.gender;
      state.cityOfBirth = action.payload.cityOfBirth;
      state.address = action.payload.address;
      state.province = action.payload.province;
      state.city = action.payload.city;
      state.cap = action.payload.cap;
      state.nationality = action.payload.nationality;
      state.state = action.payload.state;
      state.geburtsname = action.payload.geburtsname;
    },
    setDocumentData: (state, action: PayloadAction<DocumentData>) => {
      state.documentType = action.payload.documentType;
      state.documentNumber = action.payload.documentNumber;
      state.releasedBy = action.payload.releasedBy;
      state.releasePlace = action.payload.releasePlace;
      state.releaseDate = action.payload.releaseDate;
      state.expirationDate = action.payload.expirationDate;
    },
    setPrivacyConsents: (state, action: PayloadAction<PrivacyConsentsData>) => {
      state.thirdPartyInformation = action.payload.thirdPartyInformation;
      state.acceptTermAndCondition = action.payload.acceptTermAndCondition;
      state.privacyInformation = action.payload.privacyInformation;
      state.profilingUsage = action.payload.profilingUsage;
      state.marketingUsage = action.payload.marketingUsage;
    },
    setEmailSessionID: (state, action: PayloadAction<ContactsData['userSessionEmail']>) => {
      state.userSessionEmail = action.payload;
    },
    setTelephoneSessionID: (state, action: PayloadAction<ContactsData['userSessionTelephone']>) => {
      state.userSessionTelephone = action.payload;
    },
    setPromoCode: (state, action: PayloadAction<PromoData>) => {
      state.promoCode = action.payload.promoCode;
    },
    setMaximal: (state, action: PayloadAction<MaximalData>) => {
      state.weeklyMaximal = action.payload.weeklyMaximal;
    },
    setContractAccount: (state, action: PayloadAction<{ contractCode: string; pincode: string }>) => {
      (state.contractCode = action.payload.contractCode), (state.pinCode = action.payload.pincode);
    },
    setAntiFraudCode: (state, { payload: code }: PayloadAction<AntiFraudType['antiFraudCode']>) => {
      state.antiFraudCode = code;
    },
    setSignup: (state, { payload }: PayloadAction<Partial<SignupState>>) => {
      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    setExtraData: (state, { payload }: PayloadAction<ExtraInformation>) => {
      state.btag = payload.btag;
      state.provenienceCode = payload.provenienceCode;
    },
    resetSignUp: () => initialState,
  },
});

export const {
  setAccessData,
  setContacts,
  setPersonalData,
  setDocumentData,
  setPromoCode,
  setMaximal,
  setPrivacyConsents,
  setEmailSessionID,
  setTelephoneSessionID,
  setContractAccount,
  setAntiFraudCode,
  resetSignUp,
  setSignup,
  setExtraData,
} = signupSlice.actions;

export default signupSlice.reducer;
