import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Persist, { StorageKind } from 'lib/persist';
import { Session, SessionUser } from 'next-auth';
import {
  FROM_REGISTRATION,
  IS_LOGGED,
  SELECTED_CARD_COOKIE_NAME,
  TOKEN_BASE_NAME,
  TOKEN_BASE_REFRESH,
  isSnaiSite,
} from 'utility/constant';
import { isTruthy, purgeNulls } from 'utility/functions';

import { AccountCard } from 'sites/happybet/lib/api/uniqueAccountCardsResponseApi';
import { AuthState, AuthTokenType, CacheToken } from './types';
import { utcNow } from 'utility/date';
import { signOut } from './authActions';
import { DeepPartial } from 'types/swagger';

const cookie = Persist(StorageKind.cookie);
const localStorage = Persist(StorageKind.local);
const sessionStorage = Persist(StorageKind.session);

export interface SessionPayload extends Session {
  ts: number;
  isAuthenticated?: boolean;
}

const initialState: AuthState = {
  user: undefined,
  token: undefined,
  isLogged: false,
  cacheTokens: {},
};

const persistToken = (token?: AuthTokenType | undefined) => {
  const now = utcNow();
  const { accessToken, ...nextAttributes } = token ?? ({} as Partial<AuthTokenType>);

  // const prevAttributes = JSON.parse(cookie.getItem(TOKEN_BASE_REFRESH) ?? '{}') as Partial<AuthTokenType>;
  // console.log(`persist:(
  //   prev ts: ${prevAttributes?.ts ? new Date(prevAttributes.ts).toISOString() : '-'} # refresh: ${
  //   prevAttributes.refreshToken
  // }
  //   next ts: ${nextAttributes?.ts ? new Date(nextAttributes.ts).toISOString() : '-'} # refresh ${
  //   nextAttributes.refreshToken
  // }
  // )`);

  if (accessToken) {
    cookie.setItem(TOKEN_BASE_REFRESH, JSON.stringify(nextAttributes), true);
  } else {
    cookie.removeItem(TOKEN_BASE_REFRESH);
  }

  if (accessToken) {
    cookie.removeItem(TOKEN_BASE_NAME);
    cookie.setItem(TOKEN_BASE_NAME, accessToken, true);
  }
};

const setIsLoggedHandler = (state: AuthState, payload: boolean) => {
  const nextIsLogged = isTruthy(payload);
  cookie.setItem(IS_LOGGED, `${nextIsLogged}`, true);

  if (state.isLogged !== nextIsLogged) {
    sessionStorage.removeItem(FROM_REGISTRATION);
  }

  if (nextIsLogged) {
    if (isSnaiSite) {
      const { contractCode, numVersamento, totVersamenti, cartaceo } = state.user ?? {};
      if (cartaceo) {
        localStorage.setItem('cartaceo', cartaceo);
      }
      if (contractCode) {
        localStorage.setItem('cod_contratto', contractCode);
      }
      if (numVersamento) {
        localStorage.setItem('num_versamento', numVersamento);
      }
      if (totVersamenti) {
        localStorage.setItem('tot_versamenti', totVersamenti);
      }
    }

    cookie.setItem(IS_LOGGED, `${true}`, true);
  } else {
    if (isSnaiSite) {
      ['cartaceo', 'cod_contratto', 'num_versamento', 'tot_versamenti'].forEach((key) => localStorage.removeItem(key));
    }
    cookie.removeItem(IS_LOGGED);
  }

  state.isLogged = nextIsLogged;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, { payload }: PayloadAction<DeepPartial<SessionPayload>>) => {
      const { isAuthenticated, user: nextUSer, ts, ...nextToken } = payload ?? {};
      let { user: prevUser, token: prevToken } = state ?? {};

      const islogged = isTruthy(isAuthenticated);
      if (islogged) {
        state.user = (purgeNulls({
          ...(prevUser ?? {}),
          ...(nextUSer ?? {}),
        }) ?? {}) as SessionUser;
      }

      if (nextToken) {
        state.token = (purgeNulls({
          ...(prevToken ?? {}),
          ...(nextToken ?? {}),
          ts: ts ?? 0,
        }) ?? {}) as AuthTokenType;
      }

      setIsLoggedHandler(state, islogged);
      persistToken(state.token);
    },
    setToken: (state, { payload }: PayloadAction<AuthTokenType>) => {
      state.token = payload || null;
      persistToken(state.token);
    },
    setSelectedCard: (state, { payload }: PayloadAction<AccountCard | undefined>) => {
      if (payload?.carta) {
        cookie.setItem(SELECTED_CARD_COOKIE_NAME, payload.carta, true);
      } else cookie.removeItem(SELECTED_CARD_COOKIE_NAME);
      state.selectedCard = payload;
    },
    setCachedToken: (state, { payload }: PayloadAction<CacheToken>) => {
      state.cacheTokens = {
        ...state.cacheTokens,
        ...payload,
      };
    },
    resetAuthentication: (_state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.pending.type, (state) => {
      if (state.token) {
        delete state.token.refreshToken;
      }
      delete state.user;

      setIsLoggedHandler(state, false);
      persistToken(state.token);
    });
  },
});

export const { setToken, setSession, setCachedToken, setSelectedCard, resetAuthentication } = authSlice.actions;

export default authSlice.reducer;
