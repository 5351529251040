import { createSelector } from '@reduxjs/toolkit';
import { appInsight } from 'components/appInsight/AppInsight';
import { AppFragment } from 'enums/path-fragment';
import { deepEqual, shallowEqual } from 'fast-equals';
import { ApiStatus } from 'features/api/thunkUtils';
import { InfoTipoScommessa, ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { RootState } from 'lib/centralStore';
import { SportsBetRadarGiocatoreDto, SportsInfoAggiuntivaDto, SportsLiveSection } from 'types/swagger';
import { dynamicDataLoader, hasValue, isMatch, isTruthy, purgeNulls } from 'utility/functions';
import { filtersDefault, filtersLiveDefault } from './sportSlice';
import { ProssimiEventiStateType, TipoScommessaDescrizioniType } from './types';
import { KeyManagerSport } from './utils/keyManager';

export type ScommessaResponseSelectorType = (_state: RootState) => ScommessaResponse | undefined;
export type TabListResponseSelectorType = (_state: RootState) => ProssimiEventiStateType | undefined;

const _self = (state: RootState) => state.sport;
const _selfMarcatori = (state: RootState) => state.marcatori;

export const selectSelectedFilter = createSelector([_self], (state) => state.selectedFilters);
export const selectSelectedFilterLive = createSelector([_self], (state) => state.selectedFiltersLive);
export const selectSelectedFilterLiveDetails = createSelector(
  [(st, _key) => selectSelectedFilterLive(st), (_st, key) => key],
  (filters, key) => Reflect.get(filters ?? {}, key)
);

export const selectSelectedAvvenimento = createSelector([_selfMarcatori], (state) => state.avvenimentoSelected);
export const selectAvvenimentoFilter = createSelector([_self], ({ avvenimentoFilter }) => avvenimentoFilter);

export const selectInfoTemplateDisciplina = createSelector([_self], (state) => {
  const { templateDisciplina, templateDisciplinaStatus, scommessaStatus } = state ?? {};

  const templateDisciplinaValid = Object.keys(templateDisciplina ?? {}).some((key) => {
    const value = Reflect.get(templateDisciplina ?? {}, key);
    return Array.isArray(value) && isTruthy(value?.length);
  });

  return { templateDisciplinaValid, templateDisciplinaStatus, scommessaStatus };
});

export const selectInfoTemplateLive = createSelector([_self], (state) => {
  const { templateLive, templateLiveStatus, scommessaStatus } = state ?? {};
  return { templateLive, templateLiveStatus, scommessaStatus };
});

export const selectGruppoList = createSelector([_self], (state) => {
  const gruppoList = state.templateAvvenimento?.gruppoList ?? [];
  return [...gruppoList].sort((a, b) => {
    if (a.priorita < b.priorita) return -1;
    if (a.priorita > b.priorita) return 1;
    return 0;
  });
});

export const selectAvvenimentoHeaderInfo = createSelector([_self], (state) => {
  const live: SportsLiveSection = state.templateAvvenimento?.live ?? ({} as SportsLiveSection);
  const { idProgramma, idAvvenimento, externalProvider } = state.templateAvvenimento ?? {};

  return {
    idProgramma,
    idAvvenimento,
    providerId: externalProvider,
    idDisciplina: live?.idDisciplina,
    betRadarMatchId: live?.betRadarMatchId,
    betGeniusMatchId: live?.betGeniusMatchId,
    coodsId: live?.coodsId,
  };
});

export const selectTemplateManifestazione = createSelector([_self], (state) => {
  const { templateManifestazione, templateManifestazioneStatus, scommessaStatus } = state ?? {};
  return { templateManifestazione, templateManifestazioneStatus, scommessaStatus };
});

export const selectInfoTemplateManifestazione = createSelector([_self], (state) => {
  const { templateManifestazione, templateManifestazioneStatus, scommessaStatus } = state ?? {};

  const templateManifestazioneValid = hasValue(templateManifestazione);

  return { templateManifestazioneValid, templateManifestazioneStatus, scommessaStatus };
});

export const selectInfoTemplateAvvenimento = createSelector([_self], (state) => {
  const { templateAvvenimento, templateAvvenimentoStatus, avvenimentoFilter, scommessaStatus } = state ?? {};

  const templateAvvenimentoValid = hasValue(templateAvvenimento);

  return { avvenimentoFilter, templateAvvenimentoValid, templateAvvenimentoStatus, scommessaStatus };
});

export const selectScommessaStatus = createSelector([_self], (state) => state.scommessaStatus ?? ApiStatus.idle);

/**
 * @deprecated in favore di selectScommessaStatus
 *
 * */
export const selectIsScommessaLoading = createSelector([selectScommessaStatus], (scommessaStatus) => {
  return scommessaStatus === ApiStatus.idle;
});

export const selectScommessaStatusFactory = () =>
  createSelector([selectScommessaStatus], (scommessaStatus) => scommessaStatus);

/**
 * @deprecated in favore di selectScommessaStatusFactory
 *
 * */
export const selectIsScommessaLoadingFactory = () =>
  createSelector([selectIsScommessaLoading], (isLoading) => isLoading);

export const selectScommessaResponseFactory = (path: string[]) => (state: RootState) =>
  dynamicDataLoader<RootState, ScommessaResponse>(state, path, true);

export const selectCurrentPath = ({ sport, location }: RootState): string => {
  return location.pathname;
};

export const selectTemplateSlug = ({ sport }: RootState) => sport?.templateSlug;

export const selectCurrentPathFactory = () => createSelector([selectCurrentPath], (result): string => result);

/* MAIN selectors */
export const selectEsitoMapFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.esitoMap;

export const selectInfoTipoScommessaMapFactory =
  (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
    selectScommessa(state)?.infoTipoScommessaMap;

export const selectGroupScommessaMapFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.infoTipoScommessaGroupMap;

export const selectInfoAggiuntivaMapFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.infoAggiuntivaMap;

export const selectScommessaMapFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.scommessaMap;

export const selectAvvenimentoListFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.avvenimentoList ?? state.sport.templateAvvenimentoMarcatori?.avvenimentoList;

export const selectSelectedFiltersFactory = () => (state: RootState) => {
  const selectedFilters = selectSelectedFilter(state) ?? {};
  const selectedFiltersLive = selectSelectedFilterLive(state) ?? {};
  return { selectedFilters, selectedFiltersLive };
};

export const selectInfoHeaderPlayer = createSelector([_self], (state) => {
  const betRadarInfo = state.templateAvvenimentoMarcatori?.betRadarGiocatore ?? {};
  const betRadarInfoNumber: Record<keyof SportsBetRadarGiocatoreDto, number> | {} = {};

  for (const key in betRadarInfo) {
    betRadarInfoNumber[key] = Number(betRadarInfo[key]) ?? 0;
  }

  return betRadarInfoNumber as Record<keyof SportsBetRadarGiocatoreDto, number>;
});

/* DETAILED selectors */
export const selectAvvenimentoFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectAvvenimentoListFactory(selectScommessa), (_state: RootState, avvenimentoKey: string) => avvenimentoKey],
    (avvenimentoList, avvenimentoKey) => avvenimentoList?.find((avvenimento) => avvenimento.key === avvenimentoKey)
  );

export const selectAvvenimentiFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectAvvenimentoListFactory(selectScommessa),
      (_state: RootState, avvenimentiKey: Array<string>) => avvenimentiKey,
    ],
    (avvenimentoList, avvenimentiKey) => avvenimentoList?.filter(({ key }) => !!key && avvenimentiKey.includes(key))
  );

export const selectCompetitorFactory = (selectScommessa: ScommessaResponseSelectorType, competitor: 1 | 2) =>
  createSelector([selectAvvenimentoFactory(selectScommessa)], (avvenimento) =>
    competitor === 1 ? avvenimento?.firstCompetitor : avvenimento?.secondCompetitor
  );

export const selectPlayersFactory = (
  selectScommessa: ScommessaResponseSelectorType,
  infoTipoScommessaKeys: Array<string>
) =>
  createSelector(
    [
      selectAvvenimentoListFactory(selectScommessa),
      selectSelectedAvvenimento,
      selectInfoAggiuntivaMapFactory(selectScommessa),
    ],
    (avvenimentoList, selectedAvvenimento, infoAggiuntivaMap) => {
      const avvenimento = avvenimentoList?.find(({ key }) => !!key && selectedAvvenimento?.avvenimentoKey === key);
      const players: Array<SportsInfoAggiuntivaDto> = [];
      if (avvenimento) {
        const competitor = (
          selectedAvvenimento?.competitor === 1 ? avvenimento?.firstCompetitor : avvenimento?.secondCompetitor
        ).descrizione;

        infoTipoScommessaKeys.forEach((infoTipoScommessaId) => {
          const keyManager = new KeyManagerSport(`${selectedAvvenimento?.avvenimentoKey}-${infoTipoScommessaId}` ?? '');
          const scommessaKey = keyManager.scommessaKey;
          const playerList = infoAggiuntivaMap?.[scommessaKey]?.infoAggiuntivaList?.find(
            (infoAgg) => infoAgg.valore?.toUpperCase() === competitor?.toUpperCase()
          );

          (playerList?.nextInfoAgg ?? []).forEach((player) => {
            if (players.findIndex((p) => p.valore?.toUpperCase() === player.valore?.toUpperCase()) === -1) {
              players.push(player);
            }
          });
        });
        return players;
      }
      return [];
    }
  );

export const selectPlayerFromInfoAggFactory = (
  selectScommessa: ScommessaResponseSelectorType,
  scommessaKey: string,
  infoAggiuntivaHex: string
) =>
  createSelector([selectInfoAggiuntivaMapFactory(selectScommessa)], (infoAggiuntivaMap) => {
    const infoAggiuntiva = infoAggiuntivaMap?.[scommessaKey];
    if (!infoAggiuntiva) return;

    return infoAggiuntiva?.infoAggiuntivaList
      ?.flatMap((infoAgg) => {
        const index = infoAgg?.nextInfoAgg?.findIndex((player) => player.infoAggiuntivaHex === infoAggiuntivaHex) ?? -1;
        if (index > -1) {
          return infoAgg?.nextInfoAgg?.[index];
        }
        return null;
      })
      .filter((e) => !!e)[0];
  });

export const selectPlayer = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectSelectedAvvenimento,
      selectInfoAggiuntivaMapFactory(selectScommessa),
      (_stae: RootState, playerName: string) => playerName,
      (_state: RootState, _playerName: string, scommessaKey: string) => scommessaKey,
    ],
    (selectedAvvenimento, infoAggiuntivaMap, playerName, scommessaKey) => {
      const infoAgg = infoAggiuntivaMap?.[scommessaKey];

      if (infoAgg && selectedAvvenimento) {
        const player = (infoAgg.infoAggiuntivaList?.[selectedAvvenimento.competitor - 1]?.nextInfoAgg ?? []).find(
          (i) => i.valore?.toUpperCase() === playerName.toUpperCase()
        );
        return player;
      }
      return undefined;
    }
  );

export const selectEsitoFromPlayer = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector([selectPlayer(selectScommessa)], (player) => {
    if (player) {
      return player.esitoKeyList;
    }
    return [];
  });

export const selectEsitoFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectEsitoMapFactory(selectScommessa), (_state: RootState, esitoId: string) => esitoId],
    (esitoMap, esitoId) => esitoMap?.[esitoId]
  );

export const selectInfoTipoScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectInfoTipoScommessaMapFactory(selectScommessa),
      (_state: RootState, infoTipoScommessaKey: string) => infoTipoScommessaKey,
    ],
    (infoTipoScommessaMap, infoTipoScommessaKey) => infoTipoScommessaMap?.[infoTipoScommessaKey]
  );

export const selectInfoGroupScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectGroupScommessaMapFactory(selectScommessa), (_state: RootState, groupKey: string) => groupKey],
    (infoTipoGroupMap, groupKey: string) => infoTipoGroupMap?.[groupKey]
  );

export const selectInfoTipoScommessaListFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      (state: RootState) => selectScommessa(state)?.infoTipoScommessaMap,
      (state: RootState) => selectScommessa(state)?.infoTipoScommessaGroupMap,
      (state: RootState, infoTipoScommessaKeyList: Array<string>) => infoTipoScommessaKeyList,
    ],
    (infoTipoScommessaMap, infoTipoScommessaGroupMap, infoTipoScommessaKeyList) =>
      infoTipoScommessaMap
        ? infoTipoScommessaKeyList?.map((infoTipoScommessaKey) => {
            // Caso normale
            if (!isMatch(infoTipoScommessaKey, '^group-')) {
              return Reflect.get(infoTipoScommessaMap ?? {}, infoTipoScommessaKey);
            }
            if (!infoTipoScommessaGroupMap) {
              appInsight.trackException({
                error: new Error('Unable to found group map'),
                properties: {
                  infoTipoScommessaGroupMap,
                },
              });
              return;
            }

            // Caso gruppo, viene costruita un'infoTipoScommessa mergiando info dei singoli tipi e del gruppo
            let groupInfo = Reflect.get(infoTipoScommessaGroupMap ?? {}, infoTipoScommessaKey);

            /* PAS : description and tooltip could be loaded, trKeys not
          if(!groupInfo) {
            const singleKeys = `${infoTipoScommessaKey}`.replaceAll('group-', '').split('_') ?? [];
            const singleTipos = singleKeys.map((k) => infoTipoScommessaMap?.[k]).filter(Boolean);
            const toolTip = getPrefix(singleTipos.map(x => x.toolTip))
            const descrizione = getPrefix(singleTipos.map(x => x?.descrizione))
            groupInfo = {toolTip, descrizione, key:infoTipoScommessaKey} as SportsInfoTipoScommessaGroupDto
          }
          */

            const singleKeys = infoTipoScommessaKey.replaceAll('group-', '').split('_') ?? [];
            const singleTipos = singleKeys.map((k) => infoTipoScommessaMap[k]).filter(Boolean);

            // TODO : DANGEROUS CASTING
            const infoTipoScommessa = isTruthy(singleTipos?.length)
              ? ({
                  ...singleTipos[0],
                  tipoVisualizzazione: 1, // ?
                  key: infoTipoScommessaKey,
                  numeroEsiti: singleTipos?.length,
                  toolTip: groupInfo?.toolTip,
                  toolTipTrKey: groupInfo?.toolTipTrKey,
                  headers: singleTipos.map((el) => el?.headers?.[0]),
                  headersTrKey: singleTipos.map((el) => el?.headersTrKey?.[0]),
                  descrizione: groupInfo?.descrizione,
                  descrizioneTrKey: groupInfo?.descrizioneTrKey,
                } as InfoTipoScommessa)
              : undefined;
            return infoTipoScommessa;
          })
        : []
  );

export const selectInfoAggiuntivaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectInfoAggiuntivaMapFactory(selectScommessa),
      (_state: RootState, infoAggiuntivaKey: string) => infoAggiuntivaKey,
    ],
    (infoAggiuntivaMap, infoAggiuntivaKey) => infoAggiuntivaMap?.[infoAggiuntivaKey]
  );

export const selectScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectScommessaMapFactory(selectScommessa), (_state: RootState, scommessaKey: string) => scommessaKey],
    (scommessaMap, scommessaKey) => scommessaMap?.[scommessaKey]
  );

export const selectEsitoByScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectEsitoMapFactory(selectScommessa),
      selectInfoAggiuntivaMapFactory(selectScommessa),
      (_state: RootState, scommessaKey: string) => scommessaKey,
    ],
    (esitoMap, infoAggiuntivaMap, scommessaKey) => {
      const infoAgg = infoAggiuntivaMap?.[scommessaKey];
      if (infoAgg) {
        const esitoKeyList = infoAgg.infoAggiuntivaList?.[0]?.esitoKeyList;
        return esitoKeyList?.map((esitoKey) => esitoMap?.[esitoKey]);
      }
      // console.error(`Unable to retrieve infoAgg by key ${scommessaKey}`);
      return [];
    },
    {
      memoizeOptions: {
        resultEqualityCheck: deepEqual,
      },
    }
  );

export const selectDescrizioniTipoScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectGroupScommessaMapFactory(selectScommessa),
      selectInfoTipoScommessaMapFactory(selectScommessa),
      (_state: RootState, keys: Array<string | number>) => keys,
    ],
    (infoTipoGroupMap, infoTipoScommessaMap, keys): Array<TipoScommessaDescrizioniType> =>
      (keys ?? [])
        .map((infoTipoScommessaKey) => {
          if (isMatch(String(infoTipoScommessaKey), '^group-')) {
            const groupInfo = Reflect.get(infoTipoGroupMap ?? {}, infoTipoScommessaKey);

            /* PAS : description and tooltip could be loaded, trKeys not
            if(!groupInfo) {
              const singleKeys = `${infoTipoScommessaKey}`.replaceAll('group-', '').split('_') ?? [];
              const singleTipos = singleKeys.map((k) => infoTipoScommessaMap?.[k]).filter(Boolean);
              //   const toolTip = getPrefix(singleTipos.map(x => x.toolTip))
              const descrizione = getPrefix(singleTipos.map(x => x?.descrizione ?? ''))
              return {descrizione} as TipoScommessaDescrizioniType
            }
            */

            return groupInfo as TipoScommessaDescrizioniType;
          } else {
            const { descrizione, descrizioneTrKey } =
              Reflect.get(infoTipoScommessaMap ?? {}, infoTipoScommessaKey) ?? {};
            return { descrizione, descrizioneTrKey } as TipoScommessaDescrizioniType;
          }
        })
        .filter((item) => {
          const result = purgeNulls(item);
          // console.log(`purgeNulls`, item, result)
          return result !== undefined;
        }),
    {
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    }
  );

export const selectIsFilterChanged = createSelector(
  [selectCurrentPath, selectSelectedFilter, selectSelectedFilterLive],
  (path, sportFilters, livefilters) => {
    if (isMatch(path, `^(/*)${AppFragment.Live}/`)) {
      return deepEqual(livefilters, filtersLiveDefault) ? false : true;
    }

    const { quota, orario, isResetted } = filtersDefault;
    return deepEqual(sportFilters, { quota, orario, isResetted }) ? false : true;
  }
);

export const selectTabListResponseFactory =
  (path: string[]) =>
  (state: RootState): ProssimiEventiStateType | undefined =>
    dynamicDataLoader<RootState, ProssimiEventiStateType>(state, path, true);
/* TODO: senza trasformare i modelli,
ProssimiEventiStateType potrebbe essere =
  SportsScommesseProssimiEventiDto |
  SportsScommesseDirettissimeDto |
  SportsScommesseTopAntepostDto |
  SportsScommesseInEvidenzaDto |
  SportsScommesseSnaiRunnerDto
pulito, safe e autoaggiornante
 */

export const selectTabListFactory = (selectStateType: TabListResponseSelectorType) =>
  createSelector([selectStateType], (state) => state?.tabList ?? []);
