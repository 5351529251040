import React from 'react';
import dynamic from 'next/dynamic';
import { GtagScript } from './GtagScript';

export const GTMScript = dynamic(
  () => import('sites/happybet/components/googleTagManager/GTMScript').then((data) => data.GTMScript),
  { ssr: false }
);
export const GTMFrame = dynamic(
  () => import('sites/happybet/components/googleTagManager/GTMFrame').then((data) => data.GTMFrame),
  { ssr: false }
);

{
  /* 
<Script
    id="google-tag-manager"
    src="/api/gtm"
    type="text/javascript"
    strategy="lazyOnload"
    crossOrigin="anonymous"
></Script>
<noscript>
    <iframe
    src={`https://www.googletagmanager.com/ns.html?id=${GTMCODE}`}
    height="0"
    width="0"
    style={{ display: 'none', visibility: 'hidden' }}
    ></iframe>
</noscript>
*/
}

const GoogleTagManager = () => (
  <React.Fragment>
    <GTMScript />
    <GTMFrame />
    <GtagScript />
  </React.Fragment>
);

export default GoogleTagManager;
