/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { setHeaders } from 'common/types/swagger/base';

import type { HttpResponseType } from 'common/types/swagger/base';
import { checkResponse, handleException } from 'common/types/swagger/base';

export interface MovimentiBigliettoResDto {
  codiceTipoGioco?: string | undefined;
  /** @format date-time */
  dataEmissione?: string | undefined;
  /** @format date-time */
  dataModifica?: string | undefined;
  eventi: MovimentiEventiScommessaResDto;
  hasCashout: boolean;
  idBiglietto?: string | undefined;
  importo?: string | undefined;
  importoCashout?: string | undefined;
  quotaTotale?: string | undefined;
  stato?: string | undefined;
  tipologia?: string | undefined;
  vincita?: string | undefined;
  vincitaPotenziale?: string | undefined;
}

export interface MovimentiDettaglioMovimentoBettingSistemaResDto {
  importo?: Array<number> | undefined;
  numColonne?: Array<number> | undefined;
  /** @format int32 */
  numSistemi: number;
  tipologia?: Array<number> | undefined;
}

export interface MovimentiDettaglioMovimentoBettingSportDatiResDto {
  /** @format int32 */
  codiceDisciplina: number;
  /** @format int32 */
  codiceProgramma: number;
  /** @format int32 */
  codiceTipoScommessa: number;
  /** @format date-time */
  dataAvvenimento?: string | undefined;
  descrizione?: string | undefined;
  descrizioneAvvenimento?: string | undefined;
  descrizioneEvento?: string | undefined;
  descrizioneProgramma?: string | undefined;
  descrizioneSport?: string | undefined;
  descrizioneTipoScommessa?: string | undefined;
  differenzaDataAvvenimento?: string | undefined;
  /** @format int32 */
  fissa: number;
  idBetgenius?: string | undefined;
  idBetradar?: string | undefined;
  /** @format int32 */
  importoEvento: number;
  importoSistemi?: string | undefined;
  isLive: boolean;
  /** @format int32 */
  numAvvenimento: number;
  numColonneSistemi?: string | undefined;
  /** @format int32 */
  numEvento: number;
  /** @format int32 */
  numSistemi: number;
  palinsesto?: string | undefined;
  /** @format int32 */
  quotaEvento: number;
  stato?: string | undefined;
  statoLabel?: string | undefined;
  tipologiaSistemi?: string | undefined;
  valoreHandicap?: string | undefined;
}

export enum MovimentiDirezione {
  Tutto = 'Tutto',
  Entrate = 'Entrate',
  Uscite = 'Uscite',
}

export interface MovimentiEventiScommessaResDto {
  /** @format int32 */
  liveCount: number;
  /** @format int32 */
  persiCount: number;
  /** @format int32 */
  programmatiCount: number;
  /** @format int32 */
  vintiCount: number;
}

export interface MovimentiGetCashoutTotaleResDto {
  /** @format int32 */
  esito: number;
  /** @format int32 */
  saldoCarta: number;
}

export interface MovimentiGetDettaglioMovimentoReqDto {
  carta?: string | undefined;
  /** @format int32 */
  cod_tipo_gioco: number;
  id_bigl?: string | undefined;
  id_biglietto?: string | undefined;
}

export interface MovimentiGetImportoCashoutResDto {
  idBiglietto?: string | undefined;
  /** @format int32 */
  importo?: number | undefined;
  isAbilitato: boolean;
  /** @format int32 */
  prezzo: number;
}

export interface MovimentiGetMovimentiReqDto {
  carta?: string | undefined;
  /** @format date-time */
  dataA: string;
  /** @format date-time */
  dataDa: string;
  direzione: MovimentiDirezione;
  stato: MovimentiStato;
  tipologia: MovimentiTipologia;
}

export interface MovimentiGetScommesseReqDto {
  /** @format date-time */
  dataA: string;
  /** @format date-time */
  dataDa: string;
  hasCashout?: boolean | undefined;
  hasNoLimit?: boolean | undefined;
  stato: MovimentiStatoScommessa;
  tipologia: MovimentiTipoScommessa;
}

export interface MovimentiHbetGetDettaglioMovimentoBettingSportResDto {
  betslipMaxwin?: string | undefined;
  betslipTotalOdds?: string | undefined;
  betslipType?: string | undefined;
  codiceTipoGioco?: string | undefined;
  /** @format date-time */
  dataCashout?: string | undefined;
  /** @format date-time */
  dataModifica?: string | undefined;
  /** @format date-time */
  dataMovimento: string;
  /** @format date-time */
  dataOrdinamento?: string | undefined;
  /** @format date-time */
  dataScadenza?: string | undefined;
  dati?: Array<MovimentiDettaglioMovimentoBettingSportDatiResDto> | undefined;
  descrizione?: string | undefined;
  descrizioneTipoGioco?: string | undefined;
  entrata: boolean;
  hasCashout?: boolean | undefined;
  idBigl?: string | undefined;
  idBiglietto?: string | undefined;
  idRicevuta?: string | undefined;
  importo?: string | undefined;
  importoBonus?: string | undefined;
  importoCashout?: string | undefined;
  /** @format int32 */
  quotaTotale?: number | undefined;
  sistema: MovimentiDettaglioMovimentoBettingSistemaResDto;
  stato?: string | undefined;
  statoLabel?: string | undefined;
  tax?: string | undefined;
  tipologia?: string | undefined;
  tipologiaIta?: string | undefined;
  tipologiaPrelievoPending?: string | undefined;
  usedBalanceType?: Array<MovimentiUsedBalanceTypeDto> | undefined;
  vincita?: string | undefined;
  vincitaMax?: string | undefined;
  vincitaMin?: string | undefined;
  vincitaPotenziale?: string | undefined;
}

export interface MovimentiHbetMovimentoResDto {
  codiceTipoGioco?: string | undefined;
  /** @format date-time */
  dataModifica?: string | undefined;
  /** @format date-time */
  dataMovimento: string;
  /** @format date-time */
  dataOrdinamento?: string | undefined;
  /** @format date-time */
  dataScadenza?: string | undefined;
  descrizione?: string | undefined;
  descrizioneTipoGioco?: string | undefined;
  entrata: boolean;
  hasCashout?: boolean | undefined;
  idBigl?: string | undefined;
  idBiglietto?: string | undefined;
  idRicevuta?: string | undefined;
  importo?: string | undefined;
  importoCashout?: string | undefined;
  stato?: string | undefined;
  tax?: string | undefined;
  tipologia?: string | undefined;
  tipologiaIta?: string | undefined;
  tipologiaPrelievoPending?: string | undefined;
  vincita?: string | undefined;
  vincitaPotenziale?: string | undefined;
}

export interface MovimentiHbetMovimentoResDtoListPagedResDto {
  items?: Array<MovimentiHbetMovimentoResDto> | undefined;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
}

export interface MovimentiMovimentoResDto {
  codiceTipoGioco?: string | undefined;
  /** @format date-time */
  dataModifica?: string | undefined;
  /** @format date-time */
  dataMovimento: string;
  /** @format date-time */
  dataOrdinamento?: string | undefined;
  /** @format date-time */
  dataScadenza?: string | undefined;
  descrizione?: string | undefined;
  descrizioneTipoGioco?: string | undefined;
  entrata: boolean;
  hasCashout: boolean;
  idBigl?: string | undefined;
  idBiglietto?: string | undefined;
  importo?: string | undefined;
  importoCashout?: string | undefined;
  stato?: string | undefined;
  tipologia?: string | undefined;
  tipologiaPrelievoPending?: string | undefined;
  vincita?: string | undefined;
  vincitaPotenziale?: string | undefined;
}

export interface MovimentiMovimentoResDtoListPagedResDto {
  items?: Array<MovimentiMovimentoResDto> | undefined;
  /** @format int32 */
  totalCount: number;
  /** @format int32 */
  totalPages: number;
}

export interface MovimentiProblemDetails {
  detail?: string | undefined;
  instance?: string | undefined;
  /** @format int32 */
  status?: number | undefined;
  title?: string | undefined;
  type?: string | undefined;
  [key: string]: any;
}

export enum MovimentiStato {
  Tutto = 'Tutto',
  Contabilizzato = 'Contabilizzato',
  InApprovazione = 'InApprovazione',
  Erogato = 'Erogato',
  Stornato = 'Stornato',
  Rimborsato = 'Rimborsato',
  Vinta = 'Vinta',
  NonVincente = 'NonVincente',
  ChiusaConCashOut = 'ChiusaConCashOut',
  Annullata = 'Annullata',
  Aperta = 'Aperta',
  Emessi = 'Emessi',
  Pagati = 'Pagati',
  Perdenti = 'Perdenti',
  Chiusa = 'Chiusa',
}

export enum MovimentiStatoScommessa {
  Tutto = 'Tutto',
  Aperta = 'Aperta',
  Live = 'Live',
  Chiusa = 'Chiusa',
}

export enum MovimentiTipoScommessa {
  Tutto = 'Tutto',
  Sport = 'Sport',
  Ippica = 'Ippica',
  Virtual = 'Virtual',
}

export enum MovimentiTipologia {
  Tutto = 'Tutto',
  Scommessa = 'Scommessa',
  CasinoSlot = 'CasinoSlot',
  PokerCarte = 'PokerCarte',
  BingoLotteriaTotocalcio = 'BingoLotteriaTotocalcio',
  BonusRicaricaPrelievo = 'BonusRicaricaPrelievo',
}

export interface MovimentiUsedBalanceTypeDto {
  amount?: string | undefined;
  type?: string | undefined;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    const result: RequestParams = setHeaders({
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    });
    return result;
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponseType<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    })
      .then(async (response) => {
        const r = response as HttpResponse<T, E>;
        r.data = null as unknown as T;
        r.error = null as unknown as E;

        const data = !responseFormat
          ? r
          : await response[responseFormat]()
              .then((data) => {
                if (r.ok) {
                  r.data = data;
                } else {
                  r.error = data;
                }
                return r;
              })
              .catch((e) => {
                r.error = e;
                return r;
              });

        if (cancelToken) {
          this.abortControllers.delete(cancelToken);
        }

        return checkResponse<T, E>(data);
      })
      .catch((exception) => {
        return handleException<T, E>(exception);
      });
  };
}

/**
 * @title SnaiWebsite.Middleware.Movimenti
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  hbet = {
    /**
     * No description
     *
     * @tags HbetMovimenti
     * @name MovimentiCreate
     * @request POST:/hbet/movimenti
     */
    movimentiCreate: (
      data: MovimentiGetMovimentiReqDto,
      query?: {
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<MovimentiHbetMovimentoResDtoListPagedResDto, void>({
        path: `/hbet/movimenti`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetMovimenti
     * @name MovimentiDetailsCreate
     * @request POST:/hbet/movimenti/details
     */
    movimentiDetailsCreate: (data: MovimentiGetDettaglioMovimentoReqDto, params: RequestParams = {}) =>
      this.request<MovimentiHbetGetDettaglioMovimentoBettingSportResDto, MovimentiProblemDetails | void>({
        path: `/hbet/movimenti/details`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  movimenti = {
    /**
     * No description
     *
     * @tags Movimenti
     * @name MovimentiCreate
     * @request POST:/movimenti
     */
    movimentiCreate: (
      data: MovimentiGetMovimentiReqDto,
      query?: {
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<MovimentiMovimentoResDtoListPagedResDto, void>({
        path: `/movimenti`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Movimenti
     * @name DetailsCreate
     * @request POST:/movimenti/details
     */
    detailsCreate: (data: MovimentiGetDettaglioMovimentoReqDto, params: RequestParams = {}) =>
      this.request<any, MovimentiProblemDetails>({
        path: `/movimenti/details`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Movimenti
     * @name BettingCreate
     * @request POST:/movimenti/betting
     */
    bettingCreate: (
      data: MovimentiGetScommesseReqDto,
      query?: {
        /** @format int32 */
        PageNumber?: number;
        /** @format int32 */
        PageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Array<MovimentiBigliettoResDto>, void>({
        path: `/movimenti/betting`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Movimenti
     * @name CashoutList
     * @request GET:/movimenti/cashout
     */
    cashoutList: (
      query?: {
        idBiglietto?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<MovimentiGetImportoCashoutResDto, void>({
        path: `/movimenti/cashout`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Movimenti
     * @name CashoutCreate
     * @request POST:/movimenti/cashout
     */
    cashoutCreate: (
      query?: {
        idBiglietto?: string;
        /** @format int32 */
        importo?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<MovimentiGetCashoutTotaleResDto, void>({
        path: `/movimenti/cashout`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Movimenti
     * @name CashoutRealtimeCreate
     * @request POST:/movimenti/cashout/realtime
     */
    cashoutRealtimeCreate: (data: Array<string>, params: RequestParams = {}) =>
      this.request<Record<string, MovimentiGetImportoCashoutResDto>, void>({
        path: `/movimenti/cashout/realtime`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
