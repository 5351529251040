/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { setHeaders } from 'common/types/swagger/base';

import type { HttpResponseType } from 'common/types/swagger/base';
import { checkResponse, handleException } from 'common/types/swagger/base';

export type UsersActionResult = object;

export interface UsersActivateContractResDto {
  descrizione?: string | undefined;
  descrizione_codpromo?: string | undefined;
  descrizione_selflimiti?: string | undefined;
  /** @format int32 */
  esito: number;
  /** @format int32 */
  esito_codpromo: number;
  /** @format int32 */
  esito_selflimiti: number;
}

export interface UsersAllFavoritesResDto {
  listCardgame?: Array<UsersSkillGameFavoriteDto> | undefined;
  listCasino?: Array<UsersSkillGameFavoriteDto> | undefined;
  listIppica?: Array<string> | undefined;
  listSlot?: Array<UsersSkillGameFavoriteDto> | undefined;
  listSportManifestazione?: Array<UsersManifestazioneRuleItemDto> | undefined;
}

export interface UsersAllOrderedFavoritesResDto {
  listFavorite?: Array<any> | undefined;
}

export interface UsersApiGadBaseEsitoResDto {
  descrizione?: string | undefined;
  /** @format int32 */
  esito: number;
}

export interface UsersAssociatedGame {
  key?: string | undefined;
  name?: string | undefined;
}

export interface UsersAuthDataDto {
  clientType?: string | undefined;
  snaiCarta?: string | undefined;
  snaiToken?: string | undefined;
}

export enum UsersBankNetwork {
  None = 'None',
  Bnl = 'Bnl',
  Poste = 'Poste',
  Paypal = 'Paypal',
  Braintree = 'Braintree',
  Skrill = 'Skrill',
  SafechargeCC = 'SafechargeCC',
  RicaricaSnaicard = 'RicaricaSnaicard',
  RicaricaSnaipayWallet = 'RicaricaSnaipayWallet',
}

export interface UsersBaseRuleItemDto {
  /** @format date-time */
  dateTimeSaved: string;
  id?: string | undefined;
}

export interface UsersBaseRuleItemDtoFavoriteReqDto {
  rule: UsersBaseRuleItemDto;
  verticale: UsersUserDataSection;
}

export interface UsersBindOktoPaymentReqDto {
  /** @format int32 */
  codiceCsmf: number;
  email?: string | undefined;
  idRequest?: string | undefined;
  /** @format int32 */
  retry?: number | undefined;
  telefono?: string | undefined;
}

export interface UsersBonificoWithdrawAbortReqDto {
  /** @format int32 */
  codiceCsmf: number;
  descrizioneCircuito?: string | undefined;
  idRequest?: string | undefined;
  idTicket?: string | undefined;
  /** @format int32 */
  importo: number;
}

export interface UsersBonificoWithdrawReqDto {
  cf?: string | undefined;
  /** @format int32 */
  codiceCsmf: number;
  /** @format int32 */
  codiceTipoDoc: number;
  email?: string | undefined;
  iban?: string | undefined;
  /** @format int32 */
  importo: number;
  indirizzo?: string | undefined;
  modalitaPrelievo?: string | undefined;
  numeroDoc?: string | undefined;
  swiftBic?: string | undefined;
  telefono?: string | undefined;
  tipoBonifico?: string | undefined;
  username?: string | undefined;
}

export enum UsersBonus {
  None = 'None',
  Gold = 'Gold',
  Cash = 'Cash',
  Freespin = 'Freespin',
  Funbonus = 'Funbonus',
}

export interface UsersBonusCapecod {
  /** @format int32 */
  assignAmount?: number | undefined;
  /** @format int32 */
  assignAmountCents?: number | undefined;
  associatedGames?: Array<UsersAssociatedGame> | undefined;
  /** @format int32 */
  balanceAmount?: number | undefined;
  /** @format int32 */
  balanceAmountCents?: number | undefined;
  bonus?: string | undefined;
  /** @format int32 */
  bonusId: number;
  /** @format date-time */
  date: string;
  /** @format int32 */
  points?: number | undefined;
  /** @format int32 */
  pointsToUnlock?: number | undefined;
  state?: string | undefined;
  /** @format int32 */
  unlockPercMillis?: number | undefined;
  /** @format int32 */
  wonAmountCents?: number | undefined;
}

export interface UsersBonusCashDto {
  /** @format date-time */
  dataAccredito?: string | undefined;
  /** @format date-time */
  dataTermine?: string | undefined;
  /** @uniqueItems true */
  giochi?: Array<UsersGioco> | undefined;
  id?: string | undefined;
  /** @uniqueItems true */
  produttori?: Array<string> | undefined;
  promozione?: string | undefined;
  /** @format int32 */
  saldoIniziale: number;
  /** @format int32 */
  saldoResiduo: number;
  tipologia?: string | undefined;
  /** @uniqueItems true */
  verticali?: Array<string> | undefined;
}

export interface UsersBonusWagering {
  bonus_nome?: string | undefined;
  data_accettazione?: string | undefined;
  data_assegnazione?: string | undefined;
  data_autocanc?: string | undefined;
  data_scadenza?: string | undefined;
  desc_template?: string | undefined;
  /** @format int32 */
  flg_accettazione_bonus: number;
  /** @format int32 */
  flg_autocanc_bonus: number;
  /** @format int32 */
  flg_scadenza_bonus: number;
  /** @format int32 */
  flg_wagering: number;
  id_bonus?: string | undefined;
  /** @format int32 */
  impo_bonus_ini: number;
  /** @format int32 */
  impo_bonus_w: number;
  /** @format int32 */
  offset_autocanc: number;
  /** @format int32 */
  offset_scadenza: number;
  /** @format int32 */
  priorita: number;
  quota_minima?: string | undefined;
  /** @format int32 */
  stato_bonus: number;
  /** @format int32 */
  wr: number;
  /** @format int32 */
  wr_count: number;
}

export interface UsersBonusWorldmatch {
  /** @format float */
  Balance: number;
  /** @format float */
  BonusCost: number;
  /** @format float */
  CapConversion: number;
  Description?: string | undefined;
  /** @format date-time */
  ExpirationDate: string;
  /** @format int32 */
  FreeRoundCount: number;
  /** @format int32 */
  FreeRoundType: number;
  Games?: string | undefined;
  Reference?: string | undefined;
  /** @format date-time */
  StartDate: string;
  WalletType?: string | undefined;
}

export enum UsersCardLockPeriodType {
  None = 'None',
  OneMonth = 'OneMonth',
  TwoMonths = 'TwoMonths',
  ThreeMonths = 'ThreeMonths',
  SixMonths = 'SixMonths',
  OneYear = 'OneYear',
}

export enum UsersCardLockType {
  TemporaneaTrasversale = 'TemporaneaTrasversale',
  PermanenteTrasversale = 'PermanenteTrasversale',
}

export enum UsersChangeAreaEnum {
  Sport = 'sport',
  Slots = 'slots',
}

export interface UsersCheckBonusPaymentReqDto {
  carta?: string | undefined;
  circuito: UsersBankNetwork;
  /** @format int32 */
  codiceCsmf: number;
  idTransazione?: string | undefined;
  /** @format int32 */
  importo: number;
  numeroOrdine?: string | undefined;
}

export interface UsersCheckBonusPaymentResDto {
  codiceBonus?: string | undefined;
  descrizione?: string | undefined;
  /** @format int32 */
  importoBonus: number;
  nomeBonus?: string | undefined;
  numeroOrdine?: string | undefined;
  statoBonus?: string | undefined;
  tipoBonus?: string | undefined;
}

export enum UsersCheckLogin {
  Check = 'Check',
  Confirm = 'Confirm',
}

export interface UsersCheckOktoPaymentReqDto {
  /** @format int32 */
  codiceCsmf: number;
  email?: string | undefined;
  idRequest?: string | undefined;
}

export interface UsersCheckPaymentResDto {
  hasCartaceo: boolean;
  /** @format int32 */
  maxVersamento?: number | undefined;
  statoServizi: UsersStatoServiziPayment;
}

export interface UsersCheckReqDto {
  carta?: string | undefined;
  /** @format int32 */
  codiceCsmf: number;
}

export interface UsersCheckWithdrawalResDto {
  /** @format int32 */
  commissioniPrelevabilePoste?: number | undefined;
  hasCartaceo: boolean;
  /** @format int32 */
  maxPrelevabilePoste?: number | undefined;
  /** @format int32 */
  maxPrelevabileVoucher?: number | undefined;
  /** @format int32 */
  maxPrelievo?: number | undefined;
  /** @format int32 */
  minPrelevabilePoste?: number | undefined;
  /** @format int32 */
  saldoPrelevabile?: number | undefined;
  statoServizi: UsersStatoServiziWithdrawal;
}

export interface UsersConfig {
  listTipoArea?: Array<UsersTipologia> | undefined;
  listTipoLimite?: Array<UsersTipologia> | undefined;
  listTipoPeriodo?: Array<UsersTipologia> | undefined;
}

export interface UsersContattiReqDto {
  from?: string | undefined;
  subject?: string | undefined;
  text?: string | undefined;
  to?: string | undefined;
  type: UsersSendType;
}

export enum UsersContatto {
  Email = 'email',
  Telefono = 'telefono',
}

export interface UsersContractResDto {
  autoritaRilascioDoc?: string | undefined;
  capInfo?: string | undefined;
  capResidenza?: string | undefined;
  carta?: string | undefined;
  cf?: string | undefined;
  cittadinanza?: string | undefined;
  codiceContratto?: string | undefined;
  codiceTipoDoc?: string | undefined;
  codiceTipoIndirizzo?: string | undefined;
  codiceVal?: string | undefined;
  cognome?: string | undefined;
  comuneInfo?: string | undefined;
  comuneResidenza?: string | undefined;
  /** @format date-time */
  dataContratto: string;
  /** @format date-time */
  dataNascita: string;
  /** @format date-time */
  dataRilascioDoc: string;
  /** @format date-time */
  dataScadenzaDoc: string;
  email?: string | undefined;
  foroCompetente?: string | undefined;
  indirizzoInfo?: string | undefined;
  indirizzoResidenza?: string | undefined;
  luogoNascita?: string | undefined;
  luogoRilascioDoc?: string | undefined;
  nome?: string | undefined;
  numeroDoc?: string | undefined;
  provinciaInfo?: string | undefined;
  provinciaNascita?: string | undefined;
  provinciaResidenza?: string | undefined;
  saldoCarta?: string | undefined;
  sesso?: string | undefined;
  statoCarta?: string | undefined;
  telefono?: string | undefined;
  telefonoMobile?: string | undefined;
  ultimaOperazione?: string | undefined;
}

export interface UsersDati {
  /** @format int32 */
  periodo: number;
  /** @format int32 */
  stato: number;
  tipo?: string | undefined;
  /** @format int32 */
  valore: number;
}

export interface UsersFeedbackReqDto {
  comment?: string | undefined;
  /** @format int32 */
  rating?: number | undefined;
}

export interface UsersFiscalCodeReq {
  /** @format date-time */
  birthday: string;
  catastoCode?: string | undefined;
  gender: string;
  name?: string | undefined;
  surname?: string | undefined;
}

export interface UsersGetBonusResDto {
  filtroVerticale?: Array<string> | undefined;
  listBonusCash?: Array<UsersBonusCashDto> | undefined;
  listBonusGold?: Array<UsersBonusWagering> | undefined;
  listFreespinCapecod?: Array<UsersBonusCapecod> | undefined;
  listFreespinWm?: Array<UsersBonusWorldmatch> | undefined;
  listFunbonusCapecod?: Array<UsersBonusCapecod> | undefined;
  listFunbonusWm?: Array<UsersBonusWorldmatch> | undefined;
}

export interface UsersGetContractReqDto {
  carta?: string | undefined;
  /** @format int32 */
  csmf_cod: number;
}

export interface UsersGetCredentialResDto {
  cell?: string | undefined;
  /** @format int32 */
  cod_domanda_segreta: number;
  cognome?: string | undefined;
  data_nascita?: string | undefined;
  descrizione?: string | undefined;
  email?: string | undefined;
  /** @format int32 */
  esito: number;
  nome?: string | undefined;
  num_carta?: string | undefined;
  risposta?: string | undefined;
}

export interface UsersGetExtTokenResDto {
  externalToken?: string | undefined;
  externalTokenSgp?: string | undefined;
  userSessionId?: string | undefined;
}

export interface UsersGetInboxCategoriesDatiRes {
  descr_tipologia?: string | undefined;
  id?: string | undefined;
  id_tipologia?: string | undefined;
}

export interface UsersGetInboxDatiRes {
  archiviato?: string | undefined;
  avviso?: string | undefined;
  cat?: string | undefined;
  chat?: string | undefined;
  csmf_cod?: string | undefined;
  data_primo_download?: string | undefined;
  data_scad?: string | undefined;
  data_sped?: string | undefined;
  data_ultimo_download?: string | undefined;
  desc_avviso?: string | undefined;
  desc_sped?: string | undefined;
  descr?: string | undefined;
  descr_tipologia?: string | undefined;
  eliminato?: string | undefined;
  estensione?: string | undefined;
  forzalettura?: string | undefined;
  id_allegato?: string | undefined;
  id_gruppo?: string | undefined;
  id_gruppo_id_utente?: string | undefined;
  id_sped?: string | undefined;
  id_tipodoc?: string | undefined;
  id_tipologia?: string | undefined;
  id_tiposped?: string | undefined;
  id_utente?: string | undefined;
  letto?: string | undefined;
  main_folder?: string | undefined;
  note?: string | undefined;
  numero_download?: string | undefined;
  path_doc?: string | undefined;
  priorita?: string | undefined;
  sigla_lingua?: string | undefined;
  snai_consiglia?: string | undefined;
  stato?: string | undefined;
  url?: string | undefined;
}

export interface UsersGetInboxFilter {
  category?: string | undefined;
  isArchived?: boolean | undefined;
  unreaded?: boolean | undefined;
}

export interface UsersGetLimitiContantiResDto {
  attuale?: string | undefined;
  attualeScratch?: string | undefined;
  attualeSnaipay?: string | undefined;
  isBloccato: boolean;
  limiteAquistabile?: string | undefined;
  limiteContante?: string | undefined;
  next?: Array<UsersGetLimitiContantiResNextDto> | undefined;
  numTentativi?: string | undefined;
  residuoAcquistabile?: string | undefined;
  residuoContante?: string | undefined;
}

export interface UsersGetLimitiContantiResNextDto {
  /** @format date-time */
  nextData?: string | undefined;
  nextImporto?: string | undefined;
}

export interface UsersGetLimitsDatiRes {
  limite_desc?: string | undefined;
  next_da?: string | undefined;
  next_valore?: string | undefined;
  periodo?: string | undefined;
  residuo?: string | undefined;
  stato?: string | undefined;
  tipo?: string | undefined;
  totalizzato?: string | undefined;
  valore?: string | undefined;
}

export interface UsersGetLimitsReqDto {
  carta?: string | undefined;
  /** @format int32 */
  csmf_cod: number;
}

export interface UsersGetNewPasswordReqDto {
  carta?: string | undefined;
  cell?: string | undefined;
  /** @format int32 */
  cod_domanda: number;
  codice_fiscale?: string | undefined;
  cognome?: string | undefined;
  datadinascita?: string | undefined;
  email?: string | undefined;
  nome?: string | undefined;
  num_carta?: string | undefined;
  risposta?: string | undefined;
  tipoinvio?: string | undefined;
}

export interface UsersGetPrivacySettingsResDto {
  descrizione?: string | undefined;
  /** @format int32 */
  esito: number;
  flag_chat?: string | undefined;
  flag_marketing?: string | undefined;
  flag_profilazione?: string | undefined;
}

export interface UsersGetProfileResDto {
  response: UsersGetProfileResponse;
}

export interface UsersGetProfileResponse {
  BONUS_PSIP2?: Record<string, UsersJsonNode>;
  BONUS?: Record<string, UsersJsonNode>;
  BONUS_M?: Record<string, UsersJsonNode>;
  BONUS_S?: Record<string, UsersJsonNode>;
  abil_capecod?: string | undefined;
  abil_poker?: string | undefined;
  abilitazione_carta?: string | undefined;
  active_cu?: string | undefined;
  active_mg?: string | undefined;
  agenzia?: string | undefined;
  cambio_pin?: string | undefined;
  cap?: string | undefined;
  cap_info?: string | undefined;
  carta?: string | undefined;
  cartaceo?: string | undefined;
  categoria_cliente?: string | undefined;
  censimento?: string | undefined;
  citta?: string | undefined;
  citta_info?: string | undefined;
  club?: string | undefined;
  cod_contratto?: string | undefined;
  cod_domanda?: string | undefined;
  cod_prom?: string | undefined;
  cod_tipo_doc?: string | undefined;
  codfisc?: string | undefined;
  cognome?: string | undefined;
  comune?: string | undefined;
  conc_erario?: string | undefined;
  conc_ippica?: string | undefined;
  conc_sport?: string | undefined;
  contratto_lit?: string | undefined;
  contratto_lotto?: string | undefined;
  contratto_se?: string | undefined;
  country?: string | undefined;
  csmf_cod?: string | undefined;
  csmf_country?: string | undefined;
  csmf_ippica?: string | undefined;
  csmf_pvend?: string | undefined;
  csmf_sport?: string | undefined;
  csmfcod?: string | undefined;
  data_contratto?: string | undefined;
  data_contrattoDD?: string | undefined;
  data_contrattoMM?: string | undefined;
  data_contrattoYY?: string | undefined;
  data_creazione_contratto?: string | undefined;
  data_doc?: string | undefined;
  data_docDD?: string | undefined;
  data_docMM?: string | undefined;
  data_docYY?: string | undefined;
  data_fin_autoescl?: string | undefined;
  data_ini_autoescl?: string | undefined;
  data_primo_versamento?: string | undefined;
  data_sca_doc?: string | undefined;
  data_sca_docDD?: string | undefined;
  data_sca_docMM?: string | undefined;
  data_sca_docYY?: string | undefined;
  data_scad_cont?: string | undefined;
  data_scad_doc?: string | undefined;
  datanasc?: string | undefined;
  dataorarisposta?: string | undefined;
  descrizione?: string | undefined;
  descrizione_html?: string | undefined;
  doc_ril_da?: string | undefined;
  email?: string | undefined;
  enable_KYC_upload?: string | undefined;
  esito?: string | undefined;
  firmamac?: string | undefined;
  flag_chat?: string | undefined;
  flag_check_mail?: string | undefined;
  flag_check_telefono?: string | undefined;
  flag_marketing?: string | undefined;
  flag_notifica?: string | undefined;
  flag_poa?: string | undefined;
  flag_prima_login?: string | undefined;
  flag_profilazione?: string | undefined;
  flag_redirect?: string | undefined;
  flag_wagering?: string | undefined;
  flg_bw?: string | undefined;
  giorni_scad_pin?: string | undefined;
  golcheck?: string | undefined;
  id_sessione?: string | undefined;
  impo_primo_vers?: string | undefined;
  inbox_unread?: string | undefined;
  indirizzo?: string | undefined;
  indirizzo_info?: string | undefined;
  issnai?: string | undefined;
  kyc_hist?: string | undefined;
  last_login_date?: string | undefined;
  last_login_ip?: string | undefined;
  luogo_doc?: string | undefined;
  luogo_nascita?: string | undefined;
  modalita_stato_accesso?: string | undefined;
  motivo?: string | undefined;
  msgctr: UsersMessageCenter;
  n_self_limiti?: string | undefined;
  n_uploads?: string | undefined;
  nome?: string | undefined;
  num_doc?: string | undefined;
  num_versamento?: string | undefined;
  password?: string | undefined;
  pin?: string | undefined;
  prenotazione?: string | undefined;
  provincia?: string | undefined;
  provincia_info?: string | undefined;
  provincia_nasc?: string | undefined;
  provincia_nascita?: string | undefined;
  provvisoria?: string | undefined;
  pvend_ippica?: string | undefined;
  pvend_sport?: string | undefined;
  quote_smart?: string | undefined;
  risposta?: string | undefined;
  saldo_carta?: string | undefined;
  saldo_casino?: string | undefined;
  saldo_sport?: string | undefined;
  saldo_wagering?: string | undefined;
  salva_pin?: string | undefined;
  self_limiti?: Array<UsersSelfLimiti> | undefined;
  sesso?: string | undefined;
  stato_conto?: string | undefined;
  stato_kyc?: string | undefined;
  storico_pin?: string | undefined;
  tax_applied?: string | undefined;
  tax_applied_multiple?: string | undefined;
  tax_applied_single?: string | undefined;
  tax_applied_system?: string | undefined;
  tax_real?: string | undefined;
  telefono_cell?: string | undefined;
  testo_bottone?: string | undefined;
  testo_notifica?: string | undefined;
  testo_utente?: string | undefined;
  testo_utente_extlogin?: string | undefined;
  token?: string | undefined;
  tot_versamenti?: string | undefined;
  urL_notifica?: string | undefined;
  urL_redirect?: string | undefined;
  user_activity: UsersUserActivity;
  userid_ims?: string | undefined;
  username?: string | undefined;
  usersessionbonus_key?: string | undefined;
  usersessiondata_key?: string | undefined;
  welcome_message_flg?: string | undefined;
  welcome_message_jumio?: string | undefined;
  welcome_message_limits?: string | undefined;
  welcome_message_msg?: string | undefined;
  welcome_message_uniqueaccount?: string | undefined;
}

export interface UsersGetSaldoResDto {
  descrizione?: string | undefined;
  dettaglio?: Array<UsersSaldoDettaglio> | undefined;
  /** @format int32 */
  esito: number;
  num_carta?: string | undefined;
  /** @format int32 */
  righe_saldo: number;
  /** @format int32 */
  saldo: number;
}

export interface UsersGetStatsResDto {
  /** @format int32 */
  importoMedio: number;
  items?: Array<UsersStatisticsResDto> | undefined;
  /** @format int32 */
  tempoMedio: number;
}

export interface UsersGioco {
  codice?: string | undefined;
  descrizione?: string | undefined;
  produttore?: string | undefined;
  verticale?: string | undefined;
}

export interface UsersHbetCausesBanResDto {
  description?: string | undefined;
  id?: string | undefined;
  label?: string | undefined;
}

export interface UsersHbetChangeAreaReqDto {
  destinationArea: UsersChangeAreaEnum;
}

export interface UsersHbetCheckLoginReqDto {
  isLogged?: boolean | undefined;
  type: UsersCheckLogin;
}

export interface UsersHbetContractResDto {
  autoritaRilascioDoc?: string | undefined;
  capInfo?: string | undefined;
  capResidenza?: string | undefined;
  carta?: string | undefined;
  cf?: string | undefined;
  cittadinanza?: string | undefined;
  civicoResidenza?: string | undefined;
  codiceContratto?: string | undefined;
  codiceTipoDoc?: string | undefined;
  codiceTipoIndirizzo?: string | undefined;
  codiceVal?: string | undefined;
  cognome?: string | undefined;
  cognomeNascita?: string | undefined;
  comuneInfo?: string | undefined;
  comuneResidenza?: string | undefined;
  /** @format date-time */
  dataContratto: string;
  /** @format date-time */
  dataNascita: string;
  /** @format date-time */
  dataRilascioDoc: string;
  /** @format date-time */
  dataScadenzaDoc: string;
  email?: string | undefined;
  /** @format int32 */
  flagMarketing: number;
  /** @format int32 */
  flagProfilazione: number;
  foroCompetente?: string | undefined;
  iban?: string | undefined;
  indirizzoInfo?: string | undefined;
  indirizzoResidenza?: string | undefined;
  luogoNascita?: string | undefined;
  luogoRilascioDoc?: string | undefined;
  nome?: string | undefined;
  numeroDoc?: string | undefined;
  provinciaInfo?: string | undefined;
  provinciaNascita?: string | undefined;
  provinciaResidenza?: string | undefined;
  saldoCarta?: string | undefined;
  sesso?: string | undefined;
  statoCarta?: string | undefined;
  telefono?: string | undefined;
  telefonoMobile?: string | undefined;
  tipoState?: string | undefined;
  ultimaOperazione?: string | undefined;
}

export interface UsersHbetGetAutoPayoutResDto {
  /** @format date-time */
  dataModifica?: string | undefined;
  /** @format int32 */
  importo?: number | undefined;
}

export interface UsersHbetGetLimitsResDto {
  configuration: UsersConfig;
  listLimiti?: Array<UsersLimiti> | undefined;
  listStorico?: Array<UsersStorico> | undefined;
}

export interface UsersHbetGetNewPasswordReqDto {
  /** @format date-time */
  dataNascita: string;
  email?: string | undefined;
}

export interface UsersHbetJumioDocTypesData {
  label?: string | undefined;
  type?: string | undefined;
}

export interface UsersHbetJumioDocTypesDto {
  data?: Array<UsersHbetJumioDocTypesData> | undefined;
}

export interface UsersHbetRegisterContractReqDto {
  btag?: string | undefined;
  capDomicilio?: string | undefined;
  capResidenza?: string | undefined;
  cf?: string | undefined;
  codiceIobb?: string | undefined;
  codicePromo?: string | undefined;
  codiceTipoAutorita?: string | undefined;
  /** @format int32 */
  codiceTipoDoc: number;
  /** @format int32 */
  codiceTipoDomandaSegreta: number;
  cognome?: string | undefined;
  cognomeNascita?: string | undefined;
  comuneDomicilio?: string | undefined;
  comuneNascita?: string | undefined;
  comuneResidenza?: string | undefined;
  csmf_cod?: string | undefined;
  /** @format date-time */
  dataNascita: string;
  /** @format date-time */
  dataRilascioDoc: string;
  /** @format date-time */
  dataScadenzaDoc: string;
  email?: string | undefined;
  firstPage?: string | undefined;
  firstReferral?: string | undefined;
  /** @format int32 */
  flagMarketing: number;
  /** @format int32 */
  flagProfilazione: number;
  /** @format int32 */
  flagTerminiCondizioniContratto: number;
  iban?: string | undefined;
  idOtpEmail?: string | undefined;
  idOtpTelefono?: string | undefined;
  idSessione?: string | undefined;
  indirizzoDomicilio?: string | undefined;
  indirizzoResidenza?: string | undefined;
  lastReferral?: string | undefined;
  /** @format int32 */
  limiteVersamento: number;
  luogoRilascioDoc?: string | undefined;
  nazionalita?: string | undefined;
  nome?: string | undefined;
  numeroDoc?: string | undefined;
  password?: string | undefined;
  provenienza?: string | undefined;
  provinciaDomicilio?: string | undefined;
  provinciaNascita?: string | undefined;
  provinciaResidenza?: string | undefined;
  /** @minLength 1 */
  rispostaDomandaSegreta: string;
  /** @format int32 */
  sesso: number;
  spidAccess: boolean;
  telefonoMobile?: string | undefined;
  tipoCountry?: string | undefined;
  tipoState?: string | undefined;
  tipoinvio?: string | undefined;
  /** @minLength 1 */
  username: string;
}

export interface UsersHbetRegisterContractResDto {
  carta?: string | undefined;
  codiceContratto?: string | undefined;
  /** @format int32 */
  tipoConto: number;
}

export interface UsersHbetRegisterNewCardReqDto {
  btag?: string | undefined;
  codiceContratto?: string | undefined;
  password?: string | undefined;
  provenienza?: string | undefined;
  username?: string | undefined;
}

export interface UsersHbetSaldoDettaglioResDto {
  categoria?: string | undefined;
  descrizioneCategoria?: string | undefined;
  /** @format int64 */
  saldo: number;
  tipo?: string | undefined;
  tipoPrelevabile?: string | undefined;
}

export interface UsersHbetSaldoResDto {
  listSlot?: Array<UsersHbetSaldoDettaglioResDto> | undefined;
  listSport?: Array<UsersHbetSaldoDettaglioResDto> | undefined;
  /** @format int64 */
  saldoTotPrelevabile: number;
  /** @format int64 */
  saldoTotSlot: number;
  /** @format int64 */
  saldoTotSport: number;
}

export interface UsersHbetSaveLimitReqDto {
  /** @format int32 */
  codiceTipoLimite: number;
  /** @format int32 */
  codiceTipoPeriodo: number;
  descrizioneTipoArea?: string | undefined;
  /** @format int64 */
  valore: number;
}

export interface UsersHbetSearchCardsDataDto {
  carta?: string | undefined;
  codiceContratto?: string | undefined;
  /** @format int32 */
  codiceCsmf: number;
  /** @format int32 */
  codiceStatoCarta: number;
  cognome?: string | undefined;
  cognomeNascita?: string | undefined;
  /** @format date-time */
  dataNascita?: string | undefined;
  label?: string | undefined;
  luogoNascita?: string | undefined;
  nome?: string | undefined;
  username?: string | undefined;
}

export interface UsersHbetSearchCardsResDto {
  data?: Array<UsersHbetSearchCardsDataDto> | undefined;
}

export interface UsersImage {
  srcCover?: string | undefined;
  srcThumbnail?: string | undefined;
}

export interface UsersInitJumioResDto {
  descrizione?: string | undefined;
  /** @format int32 */
  esito: number;
  redirectUrl?: string | undefined;
}

export interface UsersJsonNode {
  options: UsersJsonNodeOptions;
  parent: UsersJsonNode;
  root: UsersJsonNode;
}

export interface UsersJsonNodeOptions {
  propertyNameCaseInsensitive: boolean;
}

export interface UsersLimiti {
  /** @format date-time */
  dataModifica?: string | undefined;
  /** @format date-time */
  dataValoreDopo?: string | undefined;
  stato?: string | undefined;
  tipoArea: UsersTipologia;
  tipoLimite: UsersTipologia;
  tipoPeriodo: UsersTipologia;
  totalizzato?: string | undefined;
  /** @format int64 */
  valore?: number | undefined;
  /** @format int64 */
  valoreDopo?: number | undefined;
}

export interface UsersManifestazioneRuleItemDto {
  /** @format date-time */
  dateTimeSaved: string;
  descrizione?: string | undefined;
  id?: string | undefined;
  slug?: string | undefined;
}

export interface UsersManifestazioneRuleItemDtoFavoriteReqDto {
  rule: UsersManifestazioneRuleItemDto;
  verticale: UsersUserDataSection;
}

export interface UsersMessageCenter {
  btn?: Array<UsersMessageCenterBtn> | undefined;
  /** @format int32 */
  chiusura: number;
  icona?: string | undefined;
  id_template?: string | undefined;
  messaggio?: string | undefined;
  /** @format int32 */
  noshowagain: number;
  /** @format int32 */
  nr_bottoni: number;
  priorita_messaggio?: string | undefined;
}

export interface UsersMessageCenterBtn {
  in_evidenza?: string | undefined;
  link?: string | undefined;
  target?: string | undefined;
  testo?: string | undefined;
}

export interface UsersNuveiLinkReqDto {
  carta?: string | undefined;
  /** @format int32 */
  codiceCategoriaCliente: number;
  /** @format int32 */
  codiceCategoriaDeposito?: number | undefined;
  /** @format int32 */
  codiceCsmf: number;
  customFields?: Record<string, string>;
  /** @format int32 */
  isSeamless: number;
  isWithdrawal?: boolean | undefined;
  themeId?: string | undefined;
}

export interface UsersOktoPaymentResDto {
  idRequest?: string | undefined;
}

export interface UsersOktoPaymentWithdrawReqDto {
  codiceBonus?: string | undefined;
  /** @format int32 */
  codiceCsmf: number;
  email?: string | undefined;
  idRequest?: string | undefined;
  /** @format int32 */
  importo: number;
}

export interface UsersPlayInfo {
  codeAAMS?: string | undefined;
  codeGameDesktop?: string | undefined;
  codeGameMobile?: string | undefined;
  height?: string | undefined;
  product?: string | undefined;
  width?: string | undefined;
}

export interface UsersPosteWithdrawReqDto {
  beneficiaryFiscalCode?: string | undefined;
  beneficiaryName?: string | undefined;
  beneficiarySurname?: string | undefined;
  idUserSession?: string | undefined;
  /** @format int32 */
  importo: number;
  orderingFiscalCode?: string | undefined;
  orderingName?: string | undefined;
  orderingSurname?: string | undefined;
  postepayPAN?: string | undefined;
}

export interface UsersPosteWithdrawRes {
  descrizione?: string | undefined;
  error?: string | undefined;
  errorDescription?: string | undefined;
  /** @format int32 */
  esito: number;
}

export interface UsersPostepayLinkReqDto {
  codiceBonus?: string | undefined;
  /** @format int32 */
  codiceCategoriaCliente: number;
  /** @format int32 */
  codiceCsmf: number;
  idUserSession?: string | undefined;
  /** @format int32 */
  importo: number;
  /** @format int32 */
  isSeamless: number;
}

export interface UsersPotrebbePiacertiDto {
  cardList?: Array<string> | undefined;
}

export interface UsersPromo {
  /** @format date-time */
  dataFine?: string | undefined;
  /** @format date-time */
  dataInizio?: string | undefined;
  url?: string | undefined;
}

export interface UsersRegisterContractReqDto {
  btag?: string | undefined;
  capDomicilio?: string | undefined;
  capResidenza?: string | undefined;
  cf?: string | undefined;
  codiceIobb?: string | undefined;
  codicePromo?: string | undefined;
  codiceTipoAutorita?: string | undefined;
  /** @format int32 */
  codiceTipoDoc: number;
  /** @format int32 */
  codiceTipoDomandaSegreta: number;
  cognome?: string | undefined;
  comuneDomicilio?: string | undefined;
  comuneNascita?: string | undefined;
  comuneResidenza?: string | undefined;
  csmf_cod?: string | undefined;
  /** @format date-time */
  dataNascita: string;
  /** @format date-time */
  dataRilascioDoc: string;
  /** @format date-time */
  dataScadenzaDoc: string;
  email?: string | undefined;
  firstPage?: string | undefined;
  firstReferral?: string | undefined;
  /** @format int32 */
  flagMarketing: number;
  /** @format int32 */
  flagProfilazione: number;
  idOtpEmail?: string | undefined;
  idOtpTelefono?: string | undefined;
  idSessione?: string | undefined;
  indirizzoDomicilio?: string | undefined;
  indirizzoResidenza?: string | undefined;
  lastReferral?: string | undefined;
  /** @format int32 */
  limiteVersamento: number;
  luogoRilascioDoc?: string | undefined;
  nome?: string | undefined;
  numeroDoc?: string | undefined;
  password?: string | undefined;
  provenienza?: string | undefined;
  provinciaDomicilio?: string | undefined;
  provinciaNascita?: string | undefined;
  provinciaResidenza?: string | undefined;
  /** @minLength 1 */
  rispostaDomandaSegreta: string;
  /** @format int32 */
  sesso: number;
  spidAccess: boolean;
  telefonoMobile?: string | undefined;
  tipoinvio?: string | undefined;
  /** @minLength 1 */
  username: string;
}

export interface UsersRegisterContractResDto {
  cod_contratto?: string | undefined;
  csmfcod?: string | undefined;
  descrizione?: string | undefined;
  /** @format int32 */
  esito: number;
  esito_poker?: string | undefined;
  esitoippica?: string | undefined;
  numero_carta?: string | undefined;
  pin?: string | undefined;
  saldo_carta?: string | undefined;
}

export interface UsersRegistrazioneLotteriaReqDto {
  carta?: string | undefined;
  /** @format int32 */
  tipoope?: number | undefined;
}

export interface UsersRegistrazioneLotteriaReqDtoApiGadJsonData {
  data: UsersRegistrazioneLotteriaReqDto;
  ip_address_user?: string | undefined;
  istanza_country?: string | undefined;
  language?: string | undefined;
  /** @format int32 */
  tipo_frontend: number;
}

export interface UsersRegistrazioneLotteriaReqDtoApiGadRequestDto {
  jsondata: UsersRegistrazioneLotteriaReqDtoApiGadJsonData;
}

export interface UsersRegistrazioneLotteriaRequestDto {
  /** @format int32 */
  operationType: number;
  tipoLotteria: UsersTipoLotteria;
}

export interface UsersSaldoDettaglio {
  /** @format int32 */
  bonus_fam_gioco: number;
  /** @format int32 */
  bonus_tipo_gioco: number;
  /** @format int32 */
  cod_tipo_conc: number;
  /** @format int32 */
  cod_tipo_gioco: number;
  des_saldo?: string | undefined;
  /** @format int32 */
  flg_prelevabile: number;
  /** @format int32 */
  num_mov_carta: number;
  saldo?: string | undefined;
}

export interface UsersSaveAnonymousContactReqDto {
  carta?: string | undefined;
  contatto?: string | undefined;
  tipo_contatto: UsersContatto;
}

export interface UsersSaveAnonymousContactResDto {
  descrizione?: string | undefined;
  /** @format int32 */
  esito: number;
  id_utente?: string | undefined;
}

export interface UsersSaveContactReqDto {
  contatto?: string | undefined;
  num_carta?: string | undefined;
  tipo_contatto: UsersContatto;
}

export interface UsersSaveLimitReqDto {
  carta?: string | undefined;
  /** @format int32 */
  csmf_cod: number;
  dati?: Array<UsersDati> | undefined;
  /** @format int32 */
  n_controlli: number;
}

export interface UsersSaveNewPasswordReqDto {
  carta?: string | undefined;
  password?: string | undefined;
  username?: string | undefined;
}

export interface UsersSavePrivacySettingsReqDto {
  carta?: string | undefined;
  flag_chat?: string | undefined;
  flag_marketing?: string | undefined;
  flag_profilazione?: string | undefined;
}

export interface UsersSelfLimiti {
  limite_desc?: string | undefined;
  next_da?: string | undefined;
  next_valore?: string | undefined;
  periodo?: string | undefined;
  residuo?: string | undefined;
  stato?: string | undefined;
  tipo?: string | undefined;
  totalizzato?: string | undefined;
  valore?: string | undefined;
}

export interface UsersSendSupportAuthReqDto {
  carta?: string | undefined;
  from?: string | undefined;
  sendCopy: boolean;
  subject?: string | undefined;
  text?: string | undefined;
  username?: string | undefined;
}

export interface UsersSendSupportNoAuthReqDto {
  from?: string | undefined;
  name?: string | undefined;
  subject?: string | undefined;
  surname?: string | undefined;
  text?: string | undefined;
}

export enum UsersSendType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export interface UsersSessionBonusReqDto {
  BONUS_PSIP2?: Record<string, UsersJsonNode>;
  BONUS?: Record<string, UsersJsonNode>;
  BONUS_M?: Record<string, UsersJsonNode>;
  BONUS_S?: Record<string, UsersJsonNode>;
  /** @format uuid */
  guid: string;
}

export interface UsersSessionUserReqDto {
  cartaceo?: string | undefined;
  club?: string | undefined;
  cod_prom?: string | undefined;
  codfisc?: string | undefined;
  cognome?: string | undefined;
  dataContratto?: string | undefined;
  dataScadenzaDoc?: string | undefined;
  data_primo_versamento?: string | undefined;
  flag_active_cu?: string | undefined;
  flag_marketing?: string | undefined;
  flag_notifica?: string | undefined;
  flag_profilazione?: string | undefined;
  flag_redirect?: string | undefined;
  flag_wagering?: string | undefined;
  giorni_scad_pin?: string | undefined;
  /** @format uuid */
  guid: string;
  last_login_date?: string | undefined;
  msgctr: UsersMessageCenter;
  nome?: string | undefined;
  tax_applied_multiple?: string | undefined;
  tax_applied_single?: string | undefined;
  tax_applied_system?: string | undefined;
  telefonoMobile?: string | undefined;
  testo_bottone?: string | undefined;
  testo_notifica?: string | undefined;
  testo_utente?: string | undefined;
  testo_utente_extlogin?: string | undefined;
  tot_versamenti?: string | undefined;
  urL_notifica?: string | undefined;
  urL_redirect?: string | undefined;
  welcome_message_flg?: string | undefined;
  welcome_message_jumio?: string | undefined;
  welcome_message_limits?: string | undefined;
  welcome_message_msg?: string | undefined;
  welcome_message_uniqueaccount?: string | undefined;
}

export interface UsersSetAutoesclusioneReqDto {
  cause?: Array<string> | undefined;
  periodo: UsersCardLockPeriodType;
  tipo: UsersCardLockType;
}

export interface UsersSignUpDataDto {
  acceptContract: boolean;
  acceptTermAndCondition: boolean;
  address?: string | undefined;
  antiFraudCode?: string | undefined;
  birthDate?: string | undefined;
  cap?: string | undefined;
  city?: string | undefined;
  cityOfBirth?: string | undefined;
  contractCode?: string | undefined;
  documentNumber?: string | undefined;
  documentType?: string | undefined;
  domicileAddress?: string | undefined;
  domicileCap?: string | undefined;
  domicileCity?: string | undefined;
  domicileProvince?: string | undefined;
  email?: string | undefined;
  expirationDate?: string | undefined;
  fiscalCode?: string | undefined;
  isExternalState: boolean;
  isFiscalCodeCalculated: boolean;
  isSameAsDomicile: boolean;
  isSpid: boolean;
  marketingUsage: boolean;
  name?: string | undefined;
  password?: string | undefined;
  pinCode?: string | undefined;
  privacyInformation: boolean;
  profilingUsage: boolean;
  promoCode?: string | undefined;
  province?: string | undefined;
  provinceOfBirth?: string | undefined;
  releaseDate?: string | undefined;
  releasePlace?: string | undefined;
  releasedBy?: string | undefined;
  secretAnswer?: string | undefined;
  secretQuestion?: string | undefined;
  surname?: string | undefined;
  telephone?: string | undefined;
  username?: string | undefined;
  weeklyMaximal?: string | undefined;
}

export interface UsersSkillGameFavoriteDto {
  category?: string | undefined;
  /** @format int32 */
  codicePiattaforma?: number | undefined;
  color?: string | undefined;
  description?: string | undefined;
  gameMode?: Array<string> | undefined;
  hasJackpot: boolean;
  id?: string | undefined;
  image: UsersImage;
  isExclusive: boolean;
  isFavorite: boolean;
  isInstant: boolean;
  isNew: boolean;
  /** @format int32 */
  jackpot?: number | undefined;
  playInfo: UsersPlayInfo;
  produttore?: string | undefined;
  promo: UsersPromo;
  slug?: string | undefined;
  title?: string | undefined;
  verticale?: string | undefined;
}

export interface UsersSnaicardPaymentReqDto {
  codiceBonus?: string | undefined;
  /** @format int32 */
  codiceCategoriaDeposito?: number | undefined;
  /** @format int32 */
  codiceCsmf: number;
  codiceSnaicard?: string | undefined;
}

export interface UsersSnaipayPaymentReqDto {
  codiceBonus?: string | undefined;
  /** @format int32 */
  codiceCsmf: number;
  codiceSnaipay?: string | undefined;
  idUserSession?: string | undefined;
}

export interface UsersSnaipayPaymentRes {
  accountBalance?: string | undefined;
  bonusCode?: string | undefined;
  bonusError?: string | undefined;
  depositAmount?: string | undefined;
  descrizione?: string | undefined;
  error?: string | undefined;
  errorDescription?: string | undefined;
  /** @format int32 */
  esito: number;
  orderNumber?: string | undefined;
}

export interface UsersStatisticsResDto {
  /** @format int32 */
  importoTot: number;
  periodo?: string | undefined;
  /** @format int64 */
  tempoTot: number;
  verticale?: string | undefined;
}

export interface UsersStatoServiziPayment {
  isEnabled: boolean;
  isNuveiEnabled: boolean;
  isOktoEnabled: boolean;
  isPostepayEnabled: boolean;
  isSnaicardEnabled: boolean;
  isSnaipayEnabled: boolean;
}

export interface UsersStatoServiziWithdrawal {
  isEnabled: boolean;
  isNuveiEnabled: boolean;
  isOktoEnabled: boolean;
  isPostepayEnabled: boolean;
  isVoucherEnabled: boolean;
}

export interface UsersStorico {
  /** @format date-time */
  dataModifica: string;
  etichetta?: string | undefined;
  tipoArea: UsersTipologia;
  tipoLimite: UsersTipologia;
  tipoPeriodo: UsersTipologia;
  /** @format int64 */
  valoreDopo: number;
  /** @format int64 */
  valorePrima?: number | undefined;
}

export enum UsersTipoLotteria {
  Sisal = 'sisal',
  Lottomatica = 'lottomatica',
  Grattaevinci = 'grattaevinci',
}

export interface UsersTipologia {
  /** @format int32 */
  codice: number;
  descrizione?: string | undefined;
}

export interface UsersUserActivity {
  losses?: string | undefined;
  stakes?: string | undefined;
  winnings_paied?: string | undefined;
}

export interface UsersUserAvatarDto {
  category?: string | undefined;
  id?: string | undefined;
  src?: string | undefined;
}

export interface UsersUserAvatarsDto {
  items?: Record<string, Array<UsersUserAvatarDto>>;
}

export enum UsersUserDataSection {
  FAVORITE_SLOT = 'FAVORITE_SLOT',
  FAVORITE_CASINO = 'FAVORITE_CASINO',
  FAVORITE_CARDGAME = 'FAVORITE_CARDGAME',
  FAVORITE_SPORT_MANIFESTAZIONE = 'FAVORITE_SPORT_MANIFESTAZIONE',
  FAVORITE_SPORT_SQUADRA = 'FAVORITE_SPORT_SQUADRA',
  FAVORITE_SPORT_PLAYER = 'FAVORITE_SPORT_PLAYER',
  FAVORITE_IPPICA_CAVALLO = 'FAVORITE_IPPICA_CAVALLO',
  FAVORITE_AVATARS = 'FAVORITE_AVATARS',
}

export interface UsersUserProfileDto {
  BONUS_PSIP2?: Record<string, UsersJsonNode>;
  BONUS?: Record<string, UsersJsonNode>;
  BONUS_M?: Record<string, UsersJsonNode>;
  BONUS_S?: Record<string, UsersJsonNode>;
  abil_capecod?: string | undefined;
  abil_poker?: string | undefined;
  abilitazione_carta?: string | undefined;
  active_cu?: string | undefined;
  active_mg?: string | undefined;
  agenzia?: string | undefined;
  cambio_pin?: string | undefined;
  cap?: string | undefined;
  cap_info?: string | undefined;
  carta?: string | undefined;
  cartaceo?: string | undefined;
  categoria_cliente?: string | undefined;
  censimento?: string | undefined;
  citta?: string | undefined;
  citta_info?: string | undefined;
  club?: string | undefined;
  cod_contratto?: string | undefined;
  cod_domanda?: string | undefined;
  cod_prom?: string | undefined;
  cod_tipo_doc?: string | undefined;
  codfisc?: string | undefined;
  /** @format int32 */
  codiceCategoriaCliente?: number | undefined;
  codiceFiscale?: string | undefined;
  cognome?: string | undefined;
  comune?: string | undefined;
  conc_erario?: string | undefined;
  conc_ippica?: string | undefined;
  conc_sport?: string | undefined;
  contratto_lit?: string | undefined;
  contratto_lotto?: string | undefined;
  contratto_se?: string | undefined;
  country?: string | undefined;
  /** @format date-time */
  created: string;
  csmf_cod?: string | undefined;
  csmf_country?: string | undefined;
  csmf_ippica?: string | undefined;
  csmf_pvend?: string | undefined;
  csmf_sport?: string | undefined;
  csmfcod?: string | undefined;
  /** @format date-time */
  dataNascita?: string | undefined;
  data_contratto?: string | undefined;
  data_contrattoDD?: string | undefined;
  data_contrattoMM?: string | undefined;
  data_contrattoYY?: string | undefined;
  data_creazione_contratto?: string | undefined;
  data_doc?: string | undefined;
  data_docDD?: string | undefined;
  data_docMM?: string | undefined;
  data_docYY?: string | undefined;
  data_fin_autoescl?: string | undefined;
  data_ini_autoescl?: string | undefined;
  data_primo_versamento?: string | undefined;
  data_sca_doc?: string | undefined;
  data_sca_docDD?: string | undefined;
  data_sca_docMM?: string | undefined;
  data_sca_docYY?: string | undefined;
  data_scad_cont?: string | undefined;
  data_scad_doc?: string | undefined;
  datanasc?: string | undefined;
  dataorarisposta?: string | undefined;
  descrizione?: string | undefined;
  descrizione_html?: string | undefined;
  doc_ril_da?: string | undefined;
  email?: string | undefined;
  enable_KYC_upload?: string | undefined;
  esito?: string | undefined;
  firmamac?: string | undefined;
  flag_chat?: string | undefined;
  flag_check_mail?: string | undefined;
  flag_check_telefono?: string | undefined;
  flag_marketing?: string | undefined;
  flag_notifica?: string | undefined;
  flag_poa?: string | undefined;
  flag_prima_login?: string | undefined;
  flag_profilazione?: string | undefined;
  flag_redirect?: string | undefined;
  flag_wagering?: string | undefined;
  flg_bw?: string | undefined;
  giorni_scad_pin?: string | undefined;
  golcheck?: string | undefined;
  id_sessione?: string | undefined;
  impo_primo_vers?: string | undefined;
  inbox_unread?: string | undefined;
  indirizzo?: string | undefined;
  indirizzo_info?: string | undefined;
  issnai?: string | undefined;
  kyc_hist?: string | undefined;
  last_login_date?: string | undefined;
  last_login_ip?: string | undefined;
  luogo_doc?: string | undefined;
  luogo_nascita?: string | undefined;
  modalita_stato_accesso?: string | undefined;
  motivo?: string | undefined;
  msgctr: UsersMessageCenter;
  n_self_limiti?: string | undefined;
  n_uploads?: string | undefined;
  nome?: string | undefined;
  num_doc?: string | undefined;
  num_versamento?: string | undefined;
  password?: string | undefined;
  pin?: string | undefined;
  prenotazione?: string | undefined;
  provincia?: string | undefined;
  provincia_info?: string | undefined;
  provincia_nasc?: string | undefined;
  provincia_nascita?: string | undefined;
  provvisoria?: string | undefined;
  pvend_ippica?: string | undefined;
  pvend_sport?: string | undefined;
  quote_smart?: string | undefined;
  risposta?: string | undefined;
  saldo_carta?: string | undefined;
  saldo_casino?: string | undefined;
  saldo_sport?: string | undefined;
  saldo_wagering?: string | undefined;
  salva_pin?: string | undefined;
  self_limiti?: Array<UsersSelfLimiti> | undefined;
  sesso?: string | undefined;
  stato_conto?: string | undefined;
  stato_kyc?: string | undefined;
  storico_pin?: string | undefined;
  tax_applied?: string | undefined;
  tax_applied_multiple?: string | undefined;
  tax_applied_single?: string | undefined;
  tax_applied_system?: string | undefined;
  tax_real?: string | undefined;
  telefono_cell?: string | undefined;
  testo_bottone?: string | undefined;
  testo_notifica?: string | undefined;
  testo_utente?: string | undefined;
  testo_utente_extlogin?: string | undefined;
  token?: string | undefined;
  tot_versamenti?: string | undefined;
  urL_notifica?: string | undefined;
  urL_redirect?: string | undefined;
  user_activity: UsersUserActivity;
  userid_ims?: string | undefined;
  username?: string | undefined;
  usersessionbonus_key?: string | undefined;
  usersessiondata_key?: string | undefined;
  welcome_message_flg?: string | undefined;
  welcome_message_jumio?: string | undefined;
  welcome_message_limits?: string | undefined;
  welcome_message_msg?: string | undefined;
  welcome_message_uniqueaccount?: string | undefined;
}

export interface UsersValidateAnonymousOtpReqDto {
  carta?: string | undefined;
  contatto?: string | undefined;
  id_utente?: string | undefined;
  otp_verifica?: string | undefined;
  tipo_contatto: UsersContatto;
}

export interface UsersValidateContractReqDto {
  carta?: string | undefined;
  crypt?: string | undefined;
  tipo_attivazione?: string | undefined;
}

export interface UsersValidateContractResDto {
  descrizione?: string | undefined;
  /** @format int32 */
  esito: number;
  stringa_criptata?: string | undefined;
}

export interface UsersValidateOtpReqDto {
  contatto?: string | undefined;
  num_carta?: string | undefined;
  otp_verifica?: string | undefined;
  tipo_contatto: UsersContatto;
}

export interface UsersVoucherWithdrawReqDto {
  idUserSession?: string | undefined;
  /** @format int32 */
  importo: number;
}

export interface UsersVoucherWithdrawResDto {
  descrizione?: string | undefined;
  /** @format int64 */
  saldo: number;
}

export interface UsersWalletAbortReqDto {
  idTransaction?: string | undefined;
  idUserSession?: string | undefined;
}

export interface UsersWalletLinkResDto {
  endpoint?: string | undefined;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    const result: RequestParams = setHeaders({
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    });
    return result;
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponseType<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    })
      .then(async (response) => {
        const r = response as HttpResponse<T, E>;
        r.data = null as unknown as T;
        r.error = null as unknown as E;

        const data = !responseFormat
          ? r
          : await response[responseFormat]()
              .then((data) => {
                if (r.ok) {
                  r.data = data;
                } else {
                  r.error = data;
                }
                return r;
              })
              .catch((e) => {
                r.error = e;
                return r;
              });

        if (cancelToken) {
          this.abortControllers.delete(cancelToken);
        }

        return checkResponse<T, E>(data);
      })
      .catch((exception) => {
        return handleException<T, E>(exception);
      });
  };
}

/**
 * @title SnaiWebsite.Middleware.Users
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  hbet = {
    /**
     * No description
     *
     * @tags HbetUser
     * @name UserLogoutCreate
     * @request POST:/hbet/user/logout
     */
    userLogoutCreate: (
      query?: {
        /**
         * @format int32
         * @default 0
         */
        logoutType?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/hbet/user/logout`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserChangeareaCreate
     * @request POST:/hbet/user/changearea
     */
    userChangeareaCreate: (data: UsersHbetChangeAreaReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/hbet/user/changearea`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileUsernameList
     * @request GET:/hbet/user/profile/username
     */
    userProfileUsernameList: (
      query?: {
        username?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/hbet/user/profile/username`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileEmailList
     * @request GET:/hbet/user/profile/email
     */
    userProfileEmailList: (
      query?: {
        email?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/hbet/user/profile/email`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfilePasswordCreate
     * @request POST:/hbet/user/profile/password
     */
    userProfilePasswordCreate: (data: UsersSaveNewPasswordReqDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/hbet/user/profile/password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileLimitsList
     * @request GET:/hbet/user/profile/limits
     */
    userProfileLimitsList: (params: RequestParams = {}) =>
      this.request<UsersHbetGetLimitsResDto, void>({
        path: `/hbet/user/profile/limits`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileLimitCreate
     * @request POST:/hbet/user/profile/limit
     */
    userProfileLimitCreate: (data: Array<UsersHbetSaveLimitReqDto>, params: RequestParams = {}) =>
      this.request<UsersApiGadBaseEsitoResDto, void>({
        path: `/hbet/user/profile/limit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileLimitLugasCreate
     * @request POST:/hbet/user/profile/limit/lugas
     */
    userProfileLimitLugasCreate: (
      query?: {
        /** @format int32 */
        amount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersApiGadBaseEsitoResDto, void>({
        path: `/hbet/user/profile/limit/lugas`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileLock24Create
     * @request POST:/hbet/user/profile/lock24
     */
    userProfileLock24Create: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hbet/user/profile/lock24`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileLockList
     * @request GET:/hbet/user/profile/lock
     */
    userProfileLockList: (params: RequestParams = {}) =>
      this.request<Array<UsersHbetCausesBanResDto>, void>({
        path: `/hbet/user/profile/lock`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileLockCreate
     * @request POST:/hbet/user/profile/lock
     */
    userProfileLockCreate: (data: UsersSetAutoesclusioneReqDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/hbet/user/profile/lock`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileCardsList
     * @request GET:/hbet/user/profile/cards
     */
    userProfileCardsList: (params: RequestParams = {}) =>
      this.request<UsersHbetSearchCardsResDto, void>({
        path: `/hbet/user/profile/cards`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileCheckLoginCreate
     * @request POST:/hbet/user/profile/check-login
     */
    userProfileCheckLoginCreate: (data: UsersHbetCheckLoginReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/hbet/user/profile/check-login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileContractList
     * @request GET:/hbet/user/profile/contract
     */
    userProfileContractList: (params: RequestParams = {}) =>
      this.request<UsersHbetContractResDto, void>({
        path: `/hbet/user/profile/contract`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileContractEditCreate
     * @request POST:/hbet/user/profile/contract/edit
     */
    userProfileContractEditCreate: (data: UsersHbetRegisterContractReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/hbet/user/profile/contract/edit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileContractNewCreate
     * @request POST:/hbet/user/profile/contract/new
     */
    userProfileContractNewCreate: (data: UsersHbetRegisterContractReqDto, params: RequestParams = {}) =>
      this.request<UsersHbetRegisterContractResDto, any>({
        path: `/hbet/user/profile/contract/new`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileContractNewCardCreate
     * @request POST:/hbet/user/profile/contract/new-card
     */
    userProfileContractNewCardCreate: (data: UsersHbetRegisterNewCardReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/hbet/user/profile/contract/new-card`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileContractPoaTypesList
     * @request GET:/hbet/user/profile/contract/poa/types
     */
    userProfileContractPoaTypesList: (params: RequestParams = {}) =>
      this.request<UsersHbetJumioDocTypesDto, any>({
        path: `/hbet/user/profile/contract/poa/types`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileContractPoaValidateList
     * @request GET:/hbet/user/profile/contract/poa/validate
     */
    userProfileContractPoaValidateList: (
      query?: {
        type?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersInitJumioResDto, any>({
        path: `/hbet/user/profile/contract/poa/validate`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserProfileCredentialCreate
     * @request POST:/hbet/user/profile/credential
     */
    userProfileCredentialCreate: (data: UsersHbetGetNewPasswordReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/hbet/user/profile/credential`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserWalletList
     * @request GET:/hbet/user/wallet
     */
    userWalletList: (params: RequestParams = {}) =>
      this.request<UsersHbetSaldoResDto, void>({
        path: `/hbet/user/wallet`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserWalletWithdrawalAutoList
     * @request GET:/hbet/user/wallet/withdrawal/auto
     */
    userWalletWithdrawalAutoList: (params: RequestParams = {}) =>
      this.request<UsersHbetGetAutoPayoutResDto, void>({
        path: `/hbet/user/wallet/withdrawal/auto`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HbetUser
     * @name UserWalletWithdrawalAutoCreate
     * @request POST:/hbet/user/wallet/withdrawal/auto
     */
    userWalletWithdrawalAutoCreate: (
      query?: {
        /** @format int32 */
        importo?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersApiGadBaseEsitoResDto, void>({
        path: `/hbet/user/wallet/withdrawal/auto`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags User
     * @name ExternaltokenList
     * @request GET:/user/externaltoken
     */
    externaltokenList: (
      query?: {
        CodeGame?: string;
        /** @format int32 */
        CodicePiattaforma?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersGetExtTokenResDto, any>({
        path: `/user/externaltoken`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name AuthdataList
     * @request GET:/user/authdata
     */
    authdataList: (params: RequestParams = {}) =>
      this.request<UsersAuthDataDto, any>({
        path: `/user/authdata`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FavoritesTreeList
     * @request GET:/user/favorites/tree
     */
    favoritesTreeList: (params: RequestParams = {}) =>
      this.request<UsersAllFavoritesResDto, any>({
        path: `/user/favorites/tree`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FavoritesListList
     * @request GET:/user/favorites/list
     */
    favoritesListList: (params: RequestParams = {}) =>
      this.request<UsersAllOrderedFavoritesResDto, any>({
        path: `/user/favorites/list`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FavoritesDetail
     * @request GET:/user/favorites/{section}
     */
    favoritesDetail: (section: UsersUserDataSection, params: RequestParams = {}) =>
      this.request<Array<UsersJsonNode>, any>({
        path: `/user/favorites/${section}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FavoritesCreate
     * @request POST:/user/favorites/{section}
     */
    favoritesCreate: (section: UsersUserDataSection, data: Array<UsersJsonNode>, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/favorites/${section}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FavoritesCreate2
     * @request POST:/user/favorites/{section}/{favoriteId}
     * @originalName favoritesCreate
     * @duplicate
     */
    favoritesCreate2: (
      section: UsersUserDataSection,
      favoriteId: string,
      data: Record<string, UsersJsonNode>,
      query?: {
        /** @format int32 */
        position?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/user/favorites/${section}/${favoriteId}`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FavoritesDelete
     * @request DELETE:/user/favorites/{section}/{favoriteId}
     */
    favoritesDelete: (section: UsersUserDataSection, favoriteId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/favorites/${section}/${favoriteId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FavoritesGamesCreate
     * @request POST:/user/favorites/games
     */
    favoritesGamesCreate: (data: UsersBaseRuleItemDtoFavoriteReqDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/favorites/games`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FavoritesSportsManifestazioneCreate
     * @request POST:/user/favorites/sports/manifestazione
     */
    favoritesSportsManifestazioneCreate: (
      data: UsersManifestazioneRuleItemDtoFavoriteReqDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/user/favorites/sports/manifestazione`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name PotrebbePiacerti
     * @request GET:/user/potrebbepiacerti
     */
    potrebbePiacerti: (
      query?: {
        slug?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersPotrebbePiacertiDto, any>({
        path: `/user/potrebbepiacerti`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name GetProfile
     * @request POST:/user/getprofile
     */
    getProfile: (
      query?: {
        iobb?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersGetProfileResDto, any>({
        path: `/user/getprofile`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name GetProfileUserData
     * @request POST:/user/getprofileuserdata
     */
    getProfileUserData: (
      query?: {
        iobb?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersGetProfileResDto, any>({
        path: `/user/getprofileuserdata`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name LogoutCreate
     * @request POST:/user/logout
     */
    logoutCreate: (params: RequestParams = {}) =>
      this.request<UsersActionResult, any>({
        path: `/user/logout`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileList
     * @request GET:/user/profile
     */
    profileList: (params: RequestParams = {}) =>
      this.request<UsersUserProfileDto, any>({
        path: `/user/profile`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileUsernameList
     * @request GET:/user/profile/username
     */
    profileUsernameList: (
      query?: {
        username?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/user/profile/username`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileFiscalcodeCreate
     * @request POST:/user/profile/fiscalcode
     */
    profileFiscalcodeCreate: (data: UsersFiscalCodeReq, params: RequestParams = {}) =>
      this.request<Record<string, UsersJsonNode>, any>({
        path: `/user/profile/fiscalcode`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileFiscalcodeCheckList
     * @request GET:/user/profile/fiscalcode/check
     */
    profileFiscalcodeCheckList: (
      query?: {
        fiscalCode?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersApiGadBaseEsitoResDto, void>({
        path: `/user/profile/fiscalcode/check`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileAvatarsList
     * @request GET:/user/profile/avatars
     */
    profileAvatarsList: (params: RequestParams = {}) =>
      this.request<UsersUserAvatarsDto, any>({
        path: `/user/profile/avatars`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContractContactOtpCreate
     * @request POST:/user/profile/contract/contact/otp
     */
    profileContractContactOtpCreate: (data: UsersSaveAnonymousContactReqDto, params: RequestParams = {}) =>
      this.request<UsersSaveAnonymousContactResDto, any>({
        path: `/user/profile/contract/contact/otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContractContactOtpValidateCreate
     * @request POST:/user/profile/contract/contact/otp/validate
     */
    profileContractContactOtpValidateCreate: (data: UsersValidateAnonymousOtpReqDto, params: RequestParams = {}) =>
      this.request<Record<string, UsersJsonNode>, void>({
        path: `/user/profile/contract/contact/otp/validate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContractCreate
     * @request POST:/user/profile/contract
     */
    profileContractCreate: (data: UsersGetContractReqDto, params: RequestParams = {}) =>
      this.request<UsersContractResDto, any>({
        path: `/user/profile/contract`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContractEditCreate
     * @request POST:/user/profile/contract/edit
     */
    profileContractEditCreate: (data: UsersRegisterContractReqDto, params: RequestParams = {}) =>
      this.request<UsersContractResDto, any>({
        path: `/user/profile/contract/edit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContractNewCreate
     * @request POST:/user/profile/contract/new
     */
    profileContractNewCreate: (data: UsersRegisterContractReqDto, params: RequestParams = {}) =>
      this.request<UsersRegisterContractResDto, any>({
        path: `/user/profile/contract/new`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContractActivateCreate
     * @request POST:/user/profile/contract/activate
     */
    profileContractActivateCreate: (data: UsersRegisterContractReqDto, params: RequestParams = {}) =>
      this.request<UsersActivateContractResDto, any>({
        path: `/user/profile/contract/activate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContractValidateCreate
     * @request POST:/user/profile/contract/validate
     */
    profileContractValidateCreate: (data: UsersValidateContractReqDto, params: RequestParams = {}) =>
      this.request<UsersValidateContractResDto, any>({
        path: `/user/profile/contract/validate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContractIdValidateList
     * @request GET:/user/profile/contract/id/validate
     */
    profileContractIdValidateList: (params: RequestParams = {}) =>
      this.request<UsersInitJumioResDto, any>({
        path: `/user/profile/contract/id/validate`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileCredentialList
     * @request GET:/user/profile/credential
     */
    profileCredentialList: (
      query?: {
        codiceFiscale?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersGetCredentialResDto, any>({
        path: `/user/profile/credential`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileCredentialCreate
     * @request POST:/user/profile/credential
     */
    profileCredentialCreate: (data: UsersGetNewPasswordReqDto, params: RequestParams = {}) =>
      this.request<UsersGetCredentialResDto, any>({
        path: `/user/profile/credential`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfilePasswordCreate
     * @request POST:/user/profile/password
     */
    profilePasswordCreate: (data: UsersSaveNewPasswordReqDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/profile/password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileLockCreate
     * @request POST:/user/profile/lock
     */
    profileLockCreate: (data: UsersSetAutoesclusioneReqDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/profile/lock`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileLimitsCreate
     * @request POST:/user/profile/limits
     */
    profileLimitsCreate: (data: UsersGetLimitsReqDto, params: RequestParams = {}) =>
      this.request<Array<UsersGetLimitsDatiRes>, any>({
        path: `/user/profile/limits`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileLimitCreate
     * @request POST:/user/profile/limit
     */
    profileLimitCreate: (data: UsersSaveLimitReqDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/profile/limit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfilePrivacyCreate
     * @request POST:/user/profile/privacy
     */
    profilePrivacyCreate: (data: UsersSavePrivacySettingsReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/profile/privacy`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfilePrivacyList
     * @request GET:/user/profile/privacy
     */
    profilePrivacyList: (params: RequestParams = {}) =>
      this.request<UsersGetPrivacySettingsResDto, void>({
        path: `/user/profile/privacy`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContactOtpCreate
     * @request POST:/user/profile/contact/otp
     */
    profileContactOtpCreate: (data: UsersSaveContactReqDto, params: RequestParams = {}) =>
      this.request<UsersSaveAnonymousContactResDto, any>({
        path: `/user/profile/contact/otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ProfileContactOtpValidateCreate
     * @request POST:/user/profile/contact/otp/validate
     */
    profileContactOtpValidateCreate: (data: UsersValidateOtpReqDto, params: RequestParams = {}) =>
      this.request<Record<string, UsersJsonNode>, any>({
        path: `/user/profile/contact/otp/validate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name BonusCarrelloSportOfflineList
     * @request GET:/user/bonus-carrello-sport-offline
     */
    bonusCarrelloSportOfflineList: (params: RequestParams = {}) =>
      this.request<Record<string, UsersJsonNode>, void>({
        path: `/user/bonus-carrello-sport-offline`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name BonusCarrelloIppicaOfflineList
     * @request GET:/user/bonus-carrello-ippica-offline
     */
    bonusCarrelloIppicaOfflineList: (params: RequestParams = {}) =>
      this.request<Record<string, UsersJsonNode>, void>({
        path: `/user/bonus-carrello-ippica-offline`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name BonusDetail
     * @request GET:/user/bonus/{type}
     */
    bonusDetail: (
      type: UsersBonus,
      query?: {
        verticale?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersGetBonusResDto, void>({
        path: `/user/bonus/${type}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name RegistrazionelotteriaCreate
     * @request POST:/user/registrazionelotteria
     */
    registrazionelotteriaCreate: (data: UsersRegistrazioneLotteriaRequestDto, params: RequestParams = {}) =>
      this.request<UsersRegistrazioneLotteriaReqDtoApiGadRequestDto, any>({
        path: `/user/registrazionelotteria`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FeedbackRegistrationCreate
     * @request POST:/user/feedback/registration
     */
    feedbackRegistrationCreate: (data: UsersFeedbackReqDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/feedback/registration`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FeedbackQuitregistrationCreate
     * @request POST:/user/feedback/quitregistration
     */
    feedbackQuitregistrationCreate: (data: UsersFeedbackReqDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/feedback/quitregistration`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FeedbackActivationCreate
     * @request POST:/user/feedback/activation
     */
    feedbackActivationCreate: (data: UsersFeedbackReqDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/feedback/activation`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name InboxCreate
     * @request POST:/user/inbox
     */
    inboxCreate: (data: UsersGetInboxFilter, params: RequestParams = {}) =>
      this.request<Array<UsersGetInboxDatiRes>, any>({
        path: `/user/inbox`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name InboxCategoriesList
     * @request GET:/user/inbox/categories
     */
    inboxCategoriesList: (params: RequestParams = {}) =>
      this.request<Array<UsersGetInboxCategoriesDatiRes>, any>({
        path: `/user/inbox/categories`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name InboxCountList
     * @request GET:/user/inbox/count
     */
    inboxCountList: (params: RequestParams = {}) =>
      this.request<Record<string, UsersJsonNode>, any>({
        path: `/user/inbox/count`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name InboxDetail
     * @request GET:/user/inbox/{id}
     */
    inboxDetail: (id: string, params: RequestParams = {}) =>
      this.request<UsersGetInboxDatiRes, any>({
        path: `/user/inbox/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name InboxCreate2
     * @request POST:/user/inbox/{id}
     * @originalName inboxCreate
     * @duplicate
     */
    inboxCreate2: (
      id: string,
      query?: {
        /** @format int32 */
        action?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersApiGadBaseEsitoResDto, any>({
        path: `/user/inbox/${id}`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SessionList
     * @request GET:/user/session
     */
    sessionList: (
      query?: {
        key?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Record<string, UsersJsonNode>, any>({
        path: `/user/session`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SessionsignupdataCreate
     * @request POST:/user/sessionsignupdata
     */
    sessionsignupdataCreate: (data: UsersSignUpDataDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/user/sessionsignupdata`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SessionuserbonusCreate
     * @request POST:/user/sessionuserbonus
     */
    sessionuserbonusCreate: (data: UsersSessionBonusReqDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/user/sessionuserbonus`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SessionuserCreate
     * @request POST:/user/sessionuser
     */
    sessionuserCreate: (data: UsersSessionUserReqDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/user/sessionuser`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletList
     * @request GET:/user/wallet
     */
    walletList: (params: RequestParams = {}) =>
      this.request<UsersGetSaldoResDto, any>({
        path: `/user/wallet`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentCreate
     * @request POST:/user/wallet/payment
     */
    walletPaymentCreate: (data: UsersCheckReqDto, params: RequestParams = {}) =>
      this.request<UsersCheckPaymentResDto, void>({
        path: `/user/wallet/payment`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentNuveiCreate
     * @request POST:/user/wallet/payment/nuvei
     */
    walletPaymentNuveiCreate: (data: UsersNuveiLinkReqDto, params: RequestParams = {}) =>
      this.request<UsersWalletLinkResDto, void>({
        path: `/user/wallet/payment/nuvei`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentBonusCreate
     * @request POST:/user/wallet/payment/bonus
     */
    walletPaymentBonusCreate: (data: UsersCheckBonusPaymentReqDto, params: RequestParams = {}) =>
      this.request<UsersCheckBonusPaymentResDto, void>({
        path: `/user/wallet/payment/bonus`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentLimitsList
     * @request GET:/user/wallet/payment/limits
     */
    walletPaymentLimitsList: (
      query?: {
        /** @format int32 */
        tipoDeposito?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersGetLimitiContantiResDto, void>({
        path: `/user/wallet/payment/limits`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentPosteCreate
     * @request POST:/user/wallet/payment/poste
     */
    walletPaymentPosteCreate: (data: UsersPostepayLinkReqDto, params: RequestParams = {}) =>
      this.request<UsersWalletLinkResDto, void>({
        path: `/user/wallet/payment/poste`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentSnaipayCreate
     * @request POST:/user/wallet/payment/snaipay
     */
    walletPaymentSnaipayCreate: (data: UsersSnaipayPaymentReqDto, params: RequestParams = {}) =>
      this.request<UsersSnaipayPaymentRes, void>({
        path: `/user/wallet/payment/snaipay`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentSnaicardCreate
     * @request POST:/user/wallet/payment/snaicard
     */
    walletPaymentSnaicardCreate: (data: UsersSnaicardPaymentReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/wallet/payment/snaicard`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentOktoCheckCreate
     * @request POST:/user/wallet/payment/okto/check
     */
    walletPaymentOktoCheckCreate: (data: UsersCheckOktoPaymentReqDto, params: RequestParams = {}) =>
      this.request<UsersOktoPaymentResDto, void>({
        path: `/user/wallet/payment/okto/check`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentOktoBindCreate
     * @request POST:/user/wallet/payment/okto/bind
     */
    walletPaymentOktoBindCreate: (data: UsersBindOktoPaymentReqDto, params: RequestParams = {}) =>
      this.request<UsersOktoPaymentResDto, void>({
        path: `/user/wallet/payment/okto/bind`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletPaymentOktoCreate
     * @request POST:/user/wallet/payment/okto
     */
    walletPaymentOktoCreate: (data: UsersOktoPaymentWithdrawReqDto, params: RequestParams = {}) =>
      this.request<UsersOktoPaymentResDto, void>({
        path: `/user/wallet/payment/okto`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalCreate
     * @request POST:/user/wallet/withdrawal
     */
    walletWithdrawalCreate: (data: UsersCheckReqDto, params: RequestParams = {}) =>
      this.request<UsersCheckWithdrawalResDto, void>({
        path: `/user/wallet/withdrawal`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalNuveiCreate
     * @request POST:/user/wallet/withdrawal/nuvei
     */
    walletWithdrawalNuveiCreate: (data: UsersNuveiLinkReqDto, params: RequestParams = {}) =>
      this.request<UsersWalletLinkResDto, void>({
        path: `/user/wallet/withdrawal/nuvei`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalBonificoCreate
     * @request POST:/user/wallet/withdrawal/bonifico
     */
    walletWithdrawalBonificoCreate: (data: UsersBonificoWithdrawReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/wallet/withdrawal/bonifico`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalBonificoAbortCreate
     * @request POST:/user/wallet/withdrawal/bonifico/abort
     */
    walletWithdrawalBonificoAbortCreate: (data: UsersBonificoWithdrawAbortReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/wallet/withdrawal/bonifico/abort`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalPosteCreate
     * @request POST:/user/wallet/withdrawal/poste
     */
    walletWithdrawalPosteCreate: (data: UsersPosteWithdrawReqDto, params: RequestParams = {}) =>
      this.request<UsersPosteWithdrawRes, void>({
        path: `/user/wallet/withdrawal/poste`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalPosteAbortCreate
     * @request POST:/user/wallet/withdrawal/poste/abort
     */
    walletWithdrawalPosteAbortCreate: (data: UsersWalletAbortReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/wallet/withdrawal/poste/abort`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalVoucherCreate
     * @request POST:/user/wallet/withdrawal/voucher
     */
    walletWithdrawalVoucherCreate: (data: UsersVoucherWithdrawReqDto, params: RequestParams = {}) =>
      this.request<UsersVoucherWithdrawResDto, void>({
        path: `/user/wallet/withdrawal/voucher`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalVoucherAbortCreate
     * @request POST:/user/wallet/withdrawal/voucher/abort
     */
    walletWithdrawalVoucherAbortCreate: (data: UsersWalletAbortReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/wallet/withdrawal/voucher/abort`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalOktoCreate
     * @request POST:/user/wallet/withdrawal/okto
     */
    walletWithdrawalOktoCreate: (data: UsersOktoPaymentWithdrawReqDto, params: RequestParams = {}) =>
      this.request<UsersOktoPaymentResDto, void>({
        path: `/user/wallet/withdrawal/okto`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name WalletWithdrawalOktoAbortCreate
     * @request POST:/user/wallet/withdrawal/okto/abort
     */
    walletWithdrawalOktoAbortCreate: (data: UsersWalletAbortReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/wallet/withdrawal/okto/abort`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name StatisticsList
     * @request GET:/user/statistics
     */
    statisticsList: (
      query?: {
        period?: string;
        verticale?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<UsersGetStatsResDto, any>({
        path: `/user/statistics`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SupportNoauthCreate
     * @request POST:/user/support/noauth
     */
    supportNoauthCreate: (data: UsersSendSupportNoAuthReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/support/noauth`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name SupportAuthCreate
     * @request POST:/user/support/auth
     */
    supportAuthCreate: (data: UsersSendSupportAuthReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/support/auth`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ContactCreate
     * @request POST:/user/contact
     */
    contactCreate: (data: UsersContattiReqDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/contact`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
